import { ValidationError } from '@openphone/internal-api-client'
import { comparer, makeAutoObservable, reaction, toJS } from 'mobx'

import { isArrayOf, isString, parseDate, replaceAtIndex, uniqueOf } from '@src/lib'
import type ById from '@src/lib/ById'
import { minutes } from '@src/lib/date'
import { DisposeBag } from '@src/lib/dispose'
import isNonNull from '@src/lib/isNonNull'
import isTruthy from '@src/lib/isTruthy'
import uuid from '@src/lib/uuid'
import type Service from '@src/service'
import Collection from '@src/service/collections/Collection'
import { participantsFriendlyName } from '@src/service/model'
import type {
  ScheduledMessageModel,
  IActivity,
  Member,
  Model,
  ActivityModel,
  ParticipantModel,
} from '@src/service/model'
import type {
  ConversationParticipantStatus,
  Viewer,
} from '@src/service/transport/communication'
import type { ActiveCallToType } from '@src/service/voice/ActiveCall'

import {
  isUnreadConversation,
  userPresenceIdsToMemberPresences,
  sortViewersComparator,
  isActive,
  isMemberCurrentUser,
} from './utils'

export interface MemberPresence {
  member: Member | null
  status: ConversationParticipantStatus
  timestamp: number
}

export interface CodableConversation {
  createdAt: number | null
  deletedAt: number | null
  directNumberId: string | null
  id: string
  isNew: boolean | null
  lastActivity?: IActivity | null
  lastActivityAt: number | null
  lastActivityId: string | null
  lastSeenAt: number | null
  mutedUntil: number | null
  name: string | null
  phoneNumber: string | null
  phoneNumberId: string | null
  snoozedUntil: number | null
  unreadActivities: ById<boolean>
  unreadCount: number
  updatedAt: number | null
  userId: string | null
  viewers?: Viewer[] | null
  participants?: unknown
  meta?: Record<string, unknown>
  assignedTo?: string | null
}

class ConversationModel implements CodableConversation, Model {
  id: string = `CN${uuid()}`.replace(/-/g, '')
  createdAt: number | null = null
  deletedAt: number | null = null
  directNumberId: string | null = null
  lastActivityId: string | null = null
  lastActivityAt: number | null = null
  lastSeenAt: number | null = null
  mutedUntil: number | null = null
  name: string | null = null
  phoneNumber: string | null = null
  phoneNumberId: string | null = null
  snoozedUntil: number | null = null
  unreadActivities: ById<boolean> = {}
  unreadCount = 0
  updatedAt: number | null = null
  userId: string | null = null
  viewers?: Viewer[] | null = null
  meta: Record<string, unknown> = {}
  assignedTo: string | null = null

  // Relations
  readonly activities = new Collection<ActivityModel>({
    compare: (a, b) => (a.createdAt ?? 0) - (b.createdAt ?? 0),
  })

  // Local
  isNew: boolean | null = null

  private disposeBag = new DisposeBag()
  private cachedCompaniesFromParticipants: string[] | null = null

  constructor(
    private root: Service,
    attrs: Partial<CodableConversation> = {},
  ) {
    this.deserialize(attrs)

    makeAutoObservable(this, {})

    this.disposeBag.add(
      reaction(
        () =>
          this.participants
            .map((p) => p.contact?.company)
            .filter(isNonNull)
            .sort(),
        (result) => {
          this.cachedCompaniesFromParticipants = result
        },
        { equals: comparer.structural },
      ),
    )
  }

  get openPhoneNumber() {
    return this.root.phoneNumber.collection.get(this.phoneNumberId)
  }

  get presenceEnabled() {
    return this.openPhoneNumber?.isShared && !this.isNew
  }

  get participants(): ParticipantModel[] {
    return (
      this.phoneNumber
        ?.split(',')
        .filter(isTruthy)
        .map((p) => this.root.participant.getOrCreate(p))
        .filter(isNonNull) ?? []
    )
  }

  /**
   * Returns the ActiveCallToType array required to initiate a call with the conversation's participants
   */
  get callParticipants(): ActiveCallToType {
    return this.participants.map((p) => ({
      number: p.phoneNumber,
      userId: p.openPhoneNumber?.isShared ? null : p.member?.id ?? null,
      type: p.openPhoneNumber?.isShared
        ? 'inbox'
        : p.contact
        ? 'contact'
        : p.member
        ? 'member'
        : 'number',
    }))
  }

  get isBlocked() {
    return this.phoneNumber ? this.root.blocklist.byPhoneNumber[this.phoneNumber] : false
  }

  get isGroup(): boolean {
    return this.participants.length > 1
  }

  get isUnread(): boolean {
    return isUnreadConversation(this)
  }

  /**
   * @view Diagram explaining the logic https://www.figma.com/file/qEaCynxAoQ7NZ6NX80ir2t/Inbox-Filtering%3A-Unresponded
   */
  get isUnreplied(): boolean {
    const lastActivity = this.lastActivity

    if (lastActivity?.type === 'voicemail' && lastActivity.direction === 'incoming') {
      return true
    }

    if (lastActivity?.type === 'message' && lastActivity.direction === 'incoming') {
      return true
    }

    if (
      lastActivity?.type === 'message' &&
      lastActivity.direction === 'outgoing' &&
      lastActivity.isAutoResponse
    ) {
      return true
    }

    if (
      lastActivity?.type === 'call' &&
      lastActivity.direction === 'incoming' &&
      !lastActivity.answeredAt
    ) {
      return true
    }

    if (
      lastActivity?.type === 'call' &&
      lastActivity.direction === 'outgoing' &&
      lastActivity.isAutoResponse
    ) {
      return true
    }

    return false
  }

  get isDone(): boolean {
    return (this.snoozedUntil ?? Date.now()) > Date.now()
  }

  get isArchived(): boolean {
    return Boolean(this.deletedAt)
  }

  get isDirect(): boolean {
    return Boolean(this.directNumberId)
  }

  private get userSettings() {
    return this.root.user.getCurrentUser().settings
  }

  private get phoneNumberSettings() {
    if (!this.phoneNumberId) {
      return
    }

    return this.userSettings.getPhoneNumberSettings(this.phoneNumberId)
  }

  get isPinned(): boolean {
    return this.phoneNumberSettings?.conversations?.pinnedIds?.includes(this.id) ?? false
  }

  get pinOrder(): number {
    return this.phoneNumberSettings?.conversations?.pinnedIds?.indexOf(this.id) ?? -1
  }

  get canBePinned(): boolean {
    if (this.isDone) return false

    return !this.pinLimitReached
  }

  get pinLimitReached(): boolean {
    const pinnedIds = this.phoneNumberSettings?.conversations?.pinnedIds
    return pinnedIds
      ? pinnedIds.length >= this.root.flags.getFlag('maxPinnableConversations')
      : false
  }

  get friendlyName(): string {
    if (this.name) return this.name
    return participantsFriendlyName(this.participants)
  }

  get lastActivity(): ActivityModel | null {
    return this.lastActivityId ? this.root.activity.get(this.lastActivityId) : null
  }

  get allMembersWhoViewed(): MemberPresence[] {
    const userId = this.root.user.current?.id

    const userPresences = this.root.conversation.presence[this.id] ?? {}

    const userPresenceIds = Object.keys(userPresences)

    // If the only user who viewed is the current user, show nothing for Viewers
    if (userPresenceIds.length === 1 && userPresenceIds[0] === userId) {
      return []
    }

    const allMembersWhoViewed = userPresenceIds
      .reduce<MemberPresence[]>(
        userPresenceIdsToMemberPresences(userPresences, this.root.member),
        [],
      )
      .sort(sortViewersComparator(userId ?? ''))

    return allMembersWhoViewed
  }

  get presence(): MemberPresence[] {
    const userId = this.root.user.current?.id

    const allCurrentlyPresentMembersExcludingSelf = this.allMembersWhoViewed.filter(
      (memberPresence) =>
        isActive(memberPresence) && !isMemberCurrentUser(memberPresence.member, userId),
    )

    return allCurrentlyPresentMembersExcludingSelf
  }

  get scheduledMessages(): ScheduledMessageModel[] {
    return this.isNew
      ? this.participants
          .map((participant) =>
            this.root.scheduledMessage.findAllByParticipant(participant),
          )
          .flat()
      : this.root.scheduledMessage.findAllByConversation(this)
  }

  get companiesFromParticipants() {
    if (this.cachedCompaniesFromParticipants === null) {
      this.cachedCompaniesFromParticipants = this.getCompaniesFromParticipants()
    }

    return this.cachedCompaniesFromParticipants
  }

  get isMissingRecentActivities(): boolean {
    if (!this.lastActivityId) {
      // Conversation is new, so it's not missing recent activities
      return false
    }

    const unreadActivityIds = Object.keys(this.unreadActivities)

    const isMissingUnreadActivities = unreadActivityIds.some(
      (activityId) => !this.activities.has(activityId),
    )

    if (isMissingUnreadActivities) {
      // Conversation has unread activities that are missing from the activities collection, so it's missing recent activities
      return true
    }

    if (this.activities.has(this.lastActivityId)) {
      // Conversation activities collection has the last activity, so it's not missing recent activities
      return false
    }

    return true
  }

  /**
   * Returns the list of tag values for all contacts in a conversation
   * for the provided template id
   */
  getTagValues(templateId: string): string[] {
    return uniqueOf(isString)(
      this.participants.flatMap((participant) =>
        (participant.contact?.items ?? [])
          .filter((item) => item.template?.id === templateId && !item.deletedAt)
          .flatMap((item) => (isArrayOf(isString)(item.value) ? item.value : [])),
      ),
    )
  }

  private getCompaniesFromParticipants() {
    const companies: string[] = []
    for (const participant of this.participants) {
      if (participant?.contact?.company) {
        companies.push(participant.contact.company)
      }
    }
    return companies
  }

  addParticipant = (phoneNumber: string) => {
    if (!this.isNew) {
      throw new ValidationError(
        'Participants cannot be changed for an ongoing conversation',
      )
    }
    const phoneNumbers = this.phoneNumber?.split(',') ?? []
    this.phoneNumber = [...phoneNumbers, phoneNumber].filter(isTruthy).join(',')
    this.save()
  }

  addParticipants = (phoneNumbers: string[]) => {
    phoneNumbers.forEach((phoneNumber) => this.addParticipant(phoneNumber))
  }

  removeParticipant = (phoneNumber: string) => {
    if (!this.isNew) {
      throw new ValidationError(
        'Participants cannot be changed for an ongoing conversation',
      )
    }
    const phoneNumbers = this.phoneNumber?.split(',') ?? []
    this.phoneNumber = phoneNumbers
      .filter((n) => n !== phoneNumber)
      .filter(isTruthy)
      .join(',')
    this.save()
  }

  replaceParticipant = (newPhoneNumber: string, oldPhoneNumber: string) => {
    if (!this.isNew) {
      throw new ValidationError(
        'Participants cannot be changed in an ongoing conversation',
      )
    }
    const phoneNumbers = this.phoneNumber?.split(',') ?? []
    const existingIndex = phoneNumbers.indexOf(oldPhoneNumber)
    this.phoneNumber = replaceAtIndex(phoneNumbers, newPhoneNumber, existingIndex)
      .filter(isTruthy)
      .join(',')
    this.save()
  }

  pin = () => {
    if (!this.root.user.current || !this.phoneNumberId) return
    this.phoneNumberSettings?.conversations?.pinnedIds?.push(this.id)
    this.save()
    this.root.analytics.inbox.conversationPinned(
      this.phoneNumberSettings?.conversations?.pinnedIds?.length ?? 0,
      this.id,
      this.phoneNumberId,
    )
  }

  unpin = () => {
    const pinnedIds = this.phoneNumberSettings?.conversations?.pinnedIds ?? []
    const index = pinnedIds.findIndex((pinnedId) => pinnedId === this.id)
    if (index === -1) return

    pinnedIds.splice(index, 1)
    this.save()
    this.root.analytics.inbox.conversationUnpinned(
      pinnedIds.length ?? 0,
      this.id,
      this.phoneNumberId,
    )
  }

  toggleRead = () => {
    return this.unreadCount > 0 ? this.markAsRead() : this.markAsUnread()
  }

  markAsRead = async () => {
    this.unreadCount = 0
    this.unreadActivities = {}
    this.save()
    if (!this.isNew) {
      return this.root.conversation.markAsRead(this.id)
    }
  }

  markAsUnread = async () => {
    this.unreadCount = 1
    if (this.lastActivity) {
      this.unreadActivities = { [this.lastActivity.id]: true }
    }
    this.save()
    if (!this.isNew) {
      return this.root.conversation.markAsUnread(this.id)
    }
  }

  toggleDone = () => {
    return this.isDone ? this.markAsUndone() : this.markAsDone()
  }

  markAsDone = async () => {
    const duration = 525949200
    this.snoozedUntil = Date.now() + minutes(duration)
    this.unpin()
    this.save()
    if (!this.isNew) {
      return this.root.conversation.snooze(this.id, duration).then((res) => {
        if (this.isUnread) {
          this.markAsRead()
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        return res
      })
    }
  }

  markAsUndone = async () => {
    this.snoozedUntil = null
    this.save()
    if (!this.isNew) {
      return this.root.conversation.unsnooze(this.id)
    }
  }

  archive = () => {
    this.deletedAt = Date.now()
    this.unpin()
    this.save()
    return this.root.conversation.archive(this.id).catch((error) => {
      this.deletedAt = null
      this.save()
      throw error
    })
  }

  create() {
    return this.root.conversation.create(this)
  }

  save() {
    this.root.conversation.collection.put(this)
  }

  toggleBlock(): Promise<void> {
    if (this.isBlocked) {
      return this.unblock()
    } else {
      return this.block()
    }
  }

  async block() {
    if (this.phoneNumber) {
      await this.root.blocklist.block(this.phoneNumber)
    }
  }

  async unblock() {
    if (this.phoneNumber) {
      await this.root.blocklist.unblock(this.phoneNumber)
    }
  }

  delete() {
    this.root.conversation.collection.delete(this)

    this.root.conversation.delete(this).catch(() => {
      this.save()
    })
  }

  makeCall() {
    const from = this.openPhoneNumber
    if (from) {
      this.root.voice.startCall(from, this.callParticipants)
    }
  }

  deserialize = ({
    lastActivityAt,
    lastSeenAt,
    updatedAt,
    createdAt,
    deletedAt,
    snoozedUntil,
    lastActivity: _lastActivity,
    participants: _participants,
    ...json
  }: Partial<CodableConversation>) => {
    Object.assign(this, json)

    this.lastActivityAt = lastActivityAt ? parseDate(lastActivityAt) : null

    this.lastSeenAt = lastSeenAt ? parseDate(lastSeenAt) : null

    this.updatedAt = updatedAt ? parseDate(updatedAt) : null

    this.createdAt = createdAt ? parseDate(createdAt) : null

    this.deletedAt = deletedAt ? parseDate(deletedAt) : null

    this.snoozedUntil = snoozedUntil ? parseDate(snoozedUntil) : null

    return this
  }

  serialize = (): CodableConversation => {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      directNumberId: this.directNumberId,
      id: this.id,
      isNew: this.isNew,
      lastActivityAt: this.lastActivityAt,
      lastActivityId: this.lastActivityId,
      lastSeenAt: this.lastSeenAt,
      mutedUntil: this.mutedUntil,
      name: this.name,
      phoneNumber: this.phoneNumber,
      phoneNumberId: this.phoneNumberId,
      snoozedUntil: this.snoozedUntil,
      unreadActivities: toJS(this.unreadActivities),
      unreadCount: this.unreadCount,
      updatedAt: this.updatedAt,
      userId: this.userId,
      meta: toJS(this.meta),
      assignedTo: this.assignedTo,
    }
  }

  tearDown() {
    this.phoneNumberId = null
    this.directNumberId = null
    this.root.activity.collection.deleteBulk(this.activities.list.map((a) => a.id))
    this.activities.clear()
    this.disposeBag.dispose()
  }
}

export default ConversationModel
