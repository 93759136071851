import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useEffect, useMemo } from 'react'

import { useAppStore } from '@src/app/context'
import ReCaptchaLegalInfo from '@src/app/login/ReCaptchaLegalInfo'
import Button from '@src/component/ButtonV2'
import { Link } from '@src/component/link'
import { toFormattedDollars } from '@src/lib'
import useGoogleClientApi from '@src/lib/hooks/useGoogleClientApi'
import useInputState from '@src/lib/hooks/useInputState'
import useMetaDescription from '@src/lib/hooks/useMetaDescription'
import useTitle from '@src/lib/hooks/useTitle'
import keyboard from '@src/lib/keyboard'
import Callout from '@ui/Callout'
import TextInput from '@ui/TextInput'
import Typography from '@ui/Typography'
import { GoogleIcon } from '@ui/icons/no-tint/misc'
import { LogomarkIcon } from '@ui/icons/tint/misc'
import { Spacer } from '@ui/layout/Spacer'

import * as styles from './LoginOptions.css'

function LoginOptions() {
  const { login: loginStore, toast } = useAppStore()
  const [email, setEmail] = useInputState(loginStore.email ?? '')

  const handleCode = () => {
    loginStore.hideGoogleOneTap()
    loginStore.continueToCode(email.trim())
  }

  const handlePassword = (event: MouseEvent) => {
    event.preventDefault()
    loginStore.hideGoogleOneTap()
    loginStore.continueToPassword(email.trim())
  }

  const loginTitle = 'Log in'
  const signUpTitle = 'Sign up and start your free trial'

  const loginDescription =
    'Log back into your OpenPhone account or create an account to get started with a Free Trial.'
  const signUpDescription =
    'Create an account to start your Free OpenPhone Trial. Sign up with your email or Google account. Try now for Free!'

  useTitle(loginStore.mode === 'signup' ? signUpTitle : loginTitle)
  useMetaDescription(loginStore.mode === 'signup' ? signUpDescription : loginDescription)

  const { loaded } = useGoogleClientApi()

  useEffect(() => {
    if (!loaded) return

    if (loginStore.loading !== 'none' || loginStore.isPromptShown) {
      loginStore.hideGoogleOneTap()
    } else if (loginStore.step === 'login' || loginStore.step === 'signup') {
      loginStore.initializeGoogleOneTap()
      loginStore.showGoogleOneTap()
    }
  }, [loaded, loginStore, loginStore.loading, loginStore.isPromptShown, loginStore.step])

  const { title, subtitle } = useMemo(() => {
    if (loginStore.mode === 'signup' && loginStore.coupon) {
      const coupon = loginStore.coupon
      const discount = coupon.percent_off
        ? `${coupon.percent_off}%`
        : toFormattedDollars(coupon.amount_off ?? 0, { hideCentsIfZero: true })
      const duration =
        coupon.duration === 'forever'
          ? 'forever'
          : coupon.duration === 'once'
          ? 'one-time'
          : `for ${coupon.duration_in_months} months`

      return {
        title: (
          <>
            Redeem your <br />
            {loginStore.coupon.name} offer
          </>
        ),
        subtitle: (
          <>
            Save {discount} {duration}
          </>
        ),
      }
    } else if (loginStore.invite) {
      return {
        title: <>Join {loginStore.invite.name ?? 'your team'} on OpenPhone</>,
        subtitle: <>Create an account to start</>,
      }
    } else if (loginStore.mode === 'signup') {
      return {
        title: <>Your business phone reimagined</>,
        subtitle: <>Create an account and start your free trial</>,
      }
    } else {
      return {
        title: <>Welcome back!</>,
        subtitle: (
          <>
            It’s great to see you 👋
            <br />
            Log in to your account below
          </>
        ),
      }
    }
  }, [loginStore.coupon, loginStore.invite, loginStore.mode])

  const handleLoginWithGoogle = () => {
    if (!loaded) {
      toast.showError('Something went wrong')
      return
    }

    loginStore.loginWithGoogle()
  }

  return (
    <div className={styles.background}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <LogomarkIcon />
          <Spacer height={16} />
          <Typography variant="largeTitle" fontWeight="medium">
            {title}
          </Typography>
          <Spacer height={8} />
          <Typography variant="body" color="textSecondary">
            {subtitle}
          </Typography>
          <Spacer height={32} />
          <Button
            height={40}
            fullWidth
            className={styles.googleLogin}
            loading={loginStore.loading === 'authenticating_with_google'}
            disabled={loginStore.loading === 'authenticating_with_google'}
            onClick={handleLoginWithGoogle}
            startIcon={
              <div className={styles.googleIconBg}>
                <GoogleIcon />
              </div>
            }
          >
            Continue with Google
          </Button>
          <Spacer height={24} />
          <Typography variant="footnote" color="textTertiary">
            Or continue with email
          </Typography>
          <Spacer height={8} />
          <TextInput
            ref={loginStore.inputRef}
            autoFocus
            fullWidth
            value={email}
            type="email"
            placeholder="Email address..."
            onChange={setEmail}
            onKeyDown={keyboard.onEnter(handleCode)}
          />
          <Spacer height={14} />
          <Button
            fullWidth
            disabled={!email}
            loading={loginStore.loading === 'sending_code'}
            variant="contained"
            color="primary"
            height={40}
            onClick={handleCode}
          >
            Continue
          </Button>
          {loginStore.mode === 'login' && (
            <>
              <Spacer height={24} />
              <Callout icon="🧙‍♀️">
                We'll email you a magic code to log in password-free. Or if you prefer,
                you can log in with your{' '}
                <Link href="/login/password" onClick={handlePassword}>
                  password instead
                </Link>
                .
              </Callout>
            </>
          )}
          <Spacer height={24} />
          <Typography variant="footnote" color="textSecondary">
            {loginStore.mode === 'login'
              ? 'Don’t have an account yet?'
              : 'Already have an account?'}
          </Typography>
          <Spacer height={4} />
          <button onClick={loginStore.toggleMode} className={styles.linkButton}>
            {loginStore.mode === 'login' ? 'Sign up for free' : 'Log back in'}
          </button>
          <Spacer height={80} />
          <Typography
            variant="caption1"
            color="textTertiary"
            fontWeight="regular"
            className={styles.terms}
          >
            By continuing, you acknowledge and accept our{' '}
            <a
              href="https://www.openphone.com/terms"
              target="_blank"
              className={styles.link}
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://www.openphone.com/privacy"
              target="_blank"
              className={styles.link}
            >
              Privacy Policy
            </a>
            .
          </Typography>
          <Spacer height={15} />
          <ReCaptchaLegalInfo />
        </div>
      </div>
    </div>
  )
}

export default observer(LoginOptions)
