import { makeAutoObservable } from 'mobx'

import { isReferentiallyDeepEqual } from '@src/lib/collections'
import objectId from '@src/lib/objectId'

import type Service from '..'

import type { Model } from '.'
import { PhoneNumber } from '.'
import { Contact } from './contact'
import { Member } from './member'

export default class SearchQuery implements Model {
  id: string = objectId()
  'in': PhoneNumber[] = []
  from: (Contact | Member)[] = []
  term = ''

  constructor(
    private root: Service,
    attrs: Partial<SearchQuery> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  /**
   * A query matches another if it's the same instance of the class or if it
   * searches for the same things
   *
   * @param query - The query instance to compare.
   *
   * @returns `true` if the query search for the same things or it's the same query, otherwise `false`.
   */
  matches(query: SearchQuery): boolean {
    return (
      isReferentiallyDeepEqual(query, this) ||
      (query.term === this.term &&
        query.in.every((_, index) => query.in[index]?.id === this.in[index]?.id) &&
        query.from.every((_, index) => query.from[index]?.id === this.from[index]?.id))
    )
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.from = json.from.map((f) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      f.role === 'member'
        ? new Member(this.root).deserialize(f)
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          new Contact(this.root, f),
    )
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    this.in = json.in.map((i) => new PhoneNumber(this.root).deserialize(i))
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      in: this.in.map((i) => i.serialize()),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      from: this.from.map((i) => i.serialize()),
      term: this.term,
    }
  }
}
