import { useLayoutEffect } from 'react'

interface Size {
  width: number
  height: number
  scrollWidth: number
  scrollHeight: number
}

interface UseSizeParams {
  ref: React.RefObject<HTMLElement>
  useMutationObserver?: boolean
  onResize: (rect: Size) => void
}

export default function useSize({ ref, onResize, useMutationObserver }: UseSizeParams) {
  useLayoutEffect(() => {
    if (!ref.current) return
    const element = ref.current

    let rAF: number

    function setSize(target: Element) {
      if (rAF) {
        cancelAnimationFrame(rAF)
      }

      rAF = requestAnimationFrame(() => {
        const { width, height } = target.getBoundingClientRect()
        const { scrollWidth, scrollHeight } = target

        onResize({ width, height, scrollWidth, scrollHeight })
      })
    }

    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        setSize(entry.target)
      }
    })

    let mutationObserver: MutationObserver | null = null

    if (useMutationObserver) {
      mutationObserver = new MutationObserver(() => {
        setSize(element)
      })

      mutationObserver.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    }

    resizeObserver.observe(element)
    setSize(element)

    return () => {
      resizeObserver.unobserve(element)
      mutationObserver?.disconnect()
      cancelAnimationFrame(rAF)
    }
  }, [ref, onResize, useMutationObserver])
}
