import { action, computed, makeObservable, observable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import { toE164 } from '@src/lib/phone-number'

import type Service from '..'

import type { IActivity } from './activity'
import type { Model } from './base'
import type { IContact } from './contact'
import type { CodableConversation } from './conversation'

export interface AlertAssociations {
  activities?: IActivity[]
  conversations?: CodableConversation[]
  contacts?: IContact[]
}

type AlertLevel = 'info' | 'warn' | 'error' | 'trace' | 'debug' | 'success'

export interface CodableAlert {
  id: string
  type: string
  userId: string
  data: any
  readAt: number | null
  openedAt: number | null
  deletedAt: number | null
  updatedAt: number | null
  createdAt: number
  level: AlertLevel
}

export interface DecodableAlert {
  id: string
  type: string
  userId: string
  data: any
  readAt: number | null
  openedAt: number | null
  deletedAt: number | null
  updatedAt: number | null
  createdAt: number
  level: AlertLevel
}

abstract class BaseAlert<T extends string, D> implements Model, CodableAlert {
  id = ''
  type: T = null as unknown as T
  userId = ''
  data: D = null as unknown as D
  readAt: number | null = null
  openedAt: number | null = null
  deletedAt: number | null = null
  updatedAt: number | null = null
  createdAt: number = Date.now()
  level: AlertLevel = 'info'

  constructor(protected service: Service) {
    makeObservable(this, {
      userId: observable,
      type: observable,
      data: observable.ref,
      readAt: observable,
      openedAt: observable,
      deletedAt: observable,
      updatedAt: observable,
      createdAt: observable,
      isUnread: computed,
      associatedObjectsLoaded: computed,
      isUnopened: computed,
      isDeleted: computed,
      deserialize: action.bound,
    })
  }

  abstract get associatedObjectsLoaded(): boolean

  get isUnread() {
    return !this.readAt
  }

  get isUnopened() {
    return !this.openedAt
  }

  get isDeleted() {
    return Boolean(this.deletedAt)
  }

  serialize(): CodableAlert {
    return {
      id: this.id,
      type: this.type,
      userId: this.userId,
      data: toJS(this.data),
      readAt: this.readAt,
      openedAt: this.openedAt,
      deletedAt: this.deletedAt,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      level: this.level,
    }
  }

  deserialize(json: Partial<CodableAlert> | Partial<DecodableAlert>) {
    Object.assign(this, json)
    this.readAt = json?.readAt ? parseDate(json.readAt) : null
    this.openedAt = json?.openedAt ? parseDate(json.openedAt) : null
    this.deletedAt = json?.deletedAt ? parseDate(json.deletedAt) : null
    this.createdAt = parseDate(json.createdAt ?? 0) ?? 0
    this.updatedAt = json?.updatedAt ? parseDate(json.updatedAt) : null
    return this
  }
}

export class OrganizationExportCompleteAlert extends BaseAlert<
  'org-export-complete',
  { url: string }
> {
  override get associatedObjectsLoaded(): boolean {
    return true
  }
}

export class PortRequestCompletedAlert extends BaseAlert<
  'port-request-completed',
  { phoneNumber: string; phoneNumberName: string; userId: string }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      phoneNumber: computed,
      phoneNumberName: computed,
    })
  }

  get associatedObjectsLoaded() {
    return Boolean(this.phoneNumber)
  }

  get phoneNumber() {
    return this.service.phoneNumber.collection.list.find((phoneNumber) => {
      return phoneNumber.number === this.data.phoneNumber
    })
  }

  get phoneNumberName() {
    return `${this.data.phoneNumberName} ${toE164(this.data.phoneNumber)}`
  }
}

class ActivityAlertBase<
  T extends string,
  D extends {
    activityId: string
    conversationId: string
    userId: string
  },
> extends BaseAlert<T, D> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      user: computed,
      activity: computed,
      conversation: computed,
    })
  }

  get associatedObjectsLoaded() {
    return Boolean(this.activity)
  }

  get user() {
    return this.service.member.get(this.data.userId)
  }

  get activity() {
    return this.service.activity.get(this.data.activityId)
  }

  get conversation() {
    return this.service.conversation.collection.get(this.data.conversationId)
  }
}

abstract class ThreadAlertBase<
  T extends string,
  D extends {
    activityId: string
    commentId: string
    conversationId: string
    userId: string
  },
> extends ActivityAlertBase<T, D> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      comment: computed,
    })
  }

  override get associatedObjectsLoaded() {
    return Boolean(this.comment)
  }

  get comment() {
    return this.activity?.comments?.find((comment) => comment.id === this.data.commentId)
  }
}

export class ThreadMentionAlert extends ThreadAlertBase<
  'mention-in-activity-comment',
  { activityId: string; commentId: string; conversationId: string; userId: string }
> {}

export class ThreadReplyAlert extends ThreadAlertBase<
  'comment-on-activity' | 'reply-in-mentioned-thread' | 'reply-in-thread',
  { activityId: string; commentId: string; conversationId: string; userId: string }
> {}

export class ThreadCommentReactionAlert extends ThreadAlertBase<
  'reaction-on-activity-comment',
  {
    activityId: string
    body: string
    commentId: string
    conversationId: string
    reactionId: string
    userId: string
  }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      reaction: computed,
    })
  }

  get reaction() {
    return this.comment?.reactions?.find(
      (reaction) => reaction.id === this.data.reactionId,
    )
  }
}

export class ActivityMentionAlert extends ActivityAlertBase<
  'mention-in-activity',
  { activityId: string; conversationId: string; userId: string }
> {}

export class ThreadResolutionAlert extends ActivityAlertBase<
  'activity-resolved' | 'activity-unresolved',
  { activityId: string; conversationId: string; userId: string }
> {
  get isResolved() {
    return this.type === 'activity-resolved'
  }
}

export class ActivityReactionAlert extends ActivityAlertBase<
  'reaction-on-activity',
  {
    activityId: string
    body: string
    conversationId: string
    reactionId: string
    userId: string
  }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      reaction: computed,
    })
  }

  get reaction() {
    return this.activity?.reactions?.find(
      (reaction) => reaction.id === this.data.reactionId,
    )
  }
}

export class ContactNoteMentionAlert extends BaseAlert<
  'mention-in-contact-note',
  { contactId: string; noteId: string; userId: string }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      user: computed,
      contact: computed,
      note: computed,
    })
  }

  get associatedObjectsLoaded() {
    return Boolean(this.note)
  }

  get user() {
    return this.service.member.get(this.data.userId)
  }

  get contact() {
    return this.service.contact.get(this.data.contactId)
  }

  get note() {
    return this.contact?.notes.find((note) => note.id === this.data.noteId)
  }
}

export class CsvImportCompletedAlert extends BaseAlert<
  'contact-import-completed' | 'contact-import-errored',
  { contactImportId: string }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      csvImport: computed,
    })
  }

  get associatedObjectsLoaded() {
    return Boolean(this.csvImport)
  }

  get csvImport() {
    return this.service.contact.csvImportsV2.get(this.data.contactImportId)
  }
}

export type Alert =
  | ActivityMentionAlert
  | ActivityReactionAlert
  | ContactNoteMentionAlert
  | PortRequestCompletedAlert
  | ThreadCommentReactionAlert
  | ThreadMentionAlert
  | ThreadReplyAlert
  | ThreadResolutionAlert
  | CsvImportCompletedAlert
  | OrganizationExportCompleteAlert

export function isThreadAlert(alert: Alert): boolean {
  return alert instanceof ThreadAlertBase
}
