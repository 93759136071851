import { makeAutoObservable, toJS } from 'mobx'
import { now } from 'mobx-utils'

import { getInitials, parseDate } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import type {
  AvailabilityHours as IAvailabilityHours,
  PhoneNumberSettings,
  UserPhoneNumber,
  RoleName,
} from '@src/service/transport/account'

import type Service from '..'

import { fullName } from '.'
import type { Identity, IdentityPhone, Member, Model, PortRequestStatus } from '.'
import AvailabilityHours from './AvailabilityHours'

export interface CodablePhoneNumber {
  availabilityHours: IAvailabilityHours | null
  createdAt: number | null
  groupId: string | null
  id: string
  mutedUntil: number | null
  name: string
  number: string
  symbol: string
  role: RoleName | null
  formattedNumber: string | null
  settings: PhoneNumberSettings | null
  updatedAt: number | null
  users: UserPhoneNumber[]
  portingStatus: PortRequestStatus | null
  portRequestId: string | null
}

export class PhoneNumber implements Identity, Model, CodablePhoneNumber, IdentityPhone {
  _availabilityHours: IAvailabilityHours | null = null
  createdAt: number | null = null
  groupId: string | null = null
  id = ''
  mutedUntil: number | null = null
  // We expect name, number and symbol to be always defined
  name = ''
  number = ''
  symbol = '📞'
  role: RoleName | null = null
  formattedNumber: string | null = null
  settings: PhoneNumberSettings | null = null
  updatedAt: number | null = null
  users: UserPhoneNumber[] = []
  portingStatus: PortRequestStatus | null = null
  portRequestId: string | null = null
  group: PhoneNumberGroup | null = null

  constructor(private service: Service) {
    makeAutoObservable(this, {
      isSharedWith: false,
    })
  }

  get formattedName(): string {
    return `${this.symbol || '📱'} ${this.name}`
  }

  get shortName(): string {
    return this.name
  }

  get initials() {
    return getInitials(this.name)
  }

  get phones(): IdentityPhone[] {
    return []
  }

  get pictureSymbol() {
    return this.symbol
  }

  get emailAddresses(): string[] {
    return []
  }

  get isAnonymous(): boolean {
    return false
  }

  get muted() {
    if (!this.mutedUntil || this.mutedUntil <= Date.now()) {
      return false
    }
    return this.mutedUntil > now(1000 * 60)
  }

  get isShared(): boolean {
    return this.users.length > 1
  }

  get availabilityHours(): AvailabilityHours | null {
    return this._availabilityHours
      ? new AvailabilityHours().deserialize(this._availabilityHours)
      : null
  }

  get isOffHours(): boolean {
    return this.availabilityHours?.isOffHours ?? false
  }

  get members() {
    return this.users
      .map((u) => this.service.member.collection.get(u.id ?? null))
      .filter(isNonNull)
  }

  get userNames(): string | null {
    if (!this.users) {
      return null
    } else if (this.users.length === 1) {
      return fullName(this.users[0])
    }
    return this.users
      .map((u) => u.firstName)
      .filter((u) => u)
      .join(', ')
  }

  get isPorting() {
    return !!this.portingStatus && this.portingStatus !== 'completed'
  }

  get portRequest() {
    return this.service.portRequest.collection.get(this.portRequestId)
  }

  isSharedWith(member: Member): boolean {
    return this.users.some((user) => user.id === member.id)
  }

  update = (attributes: Partial<PhoneNumber>) => {
    Object.assign(this, attributes)
    this.service.transport.account.phoneNumbers.update(this.serialize())
  }

  mute = (until: Date) => {
    this.mutedUntil = until.getTime()
    this.service.transport.account.phoneNumbers.mute(this.serialize())
  }

  unmute = () => {
    this.mutedUntil = null
    this.service.transport.account.phoneNumbers.unmute(this.serialize())
  }

  deserialize = (attrs: Partial<CodablePhoneNumber>) => {
    if (attrs) {
      const { groupId = null, availabilityHours, ...json } = attrs
      Object.assign(this, json)
      this.groupId = groupId
      this.group = this.groupId ? new PhoneNumberGroup(this.groupId, this) : null
      this.createdAt = json.createdAt ? parseDate(json.createdAt) : null
      this.mutedUntil = json.mutedUntil ? parseDate(json.mutedUntil) : null
      this.updatedAt = json.updatedAt ? parseDate(json.updatedAt) : null
      if (typeof availabilityHours !== 'undefined') {
        this._availabilityHours = availabilityHours
      }
    }
    return this
  }

  serialize = (): CodablePhoneNumber => {
    return {
      availabilityHours: this.availabilityHours?.serialize() ?? null,
      createdAt: this.createdAt,
      formattedNumber: this.formattedNumber,
      groupId: this.groupId,
      id: this.id,
      mutedUntil: this.mutedUntil,
      name: this.name,
      number: this.number,
      role: this.role,
      settings: toJS(this.settings),
      symbol: this.symbol,
      updatedAt: this.updatedAt,
      users: toJS(this.users),
      portingStatus: this.portingStatus,
      portRequestId: this.portRequestId,
    }
  }
}

export class PhoneNumberGroup implements Identity {
  readonly pictureUrl = undefined
  readonly emailAddresses = []
  readonly isAnonymous = false

  constructor(
    public readonly id: string,
    readonly phoneNumber: PhoneNumber,
  ) {
    makeAutoObservable(this, {})
  }

  get name(): string {
    return this.phoneNumber.name
  }

  get shortName(): string {
    return this.phoneNumber.name
  }

  get initials(): string {
    return getInitials(this.name)
  }

  get pictureSymbol(): string {
    return this.phoneNumber.symbol
  }

  get members() {
    return this.phoneNumber.members
  }

  get phones(): IdentityPhone[] {
    return [
      {
        id: this.phoneNumber.id,
        name: this.phoneNumber.name,
        symbol: this.phoneNumber.symbol,
        number: this.phoneNumber.number,
        isOffHours: this.phoneNumber.isOffHours,
        isShared: this.phoneNumber.isShared,
      },
    ]
  }
}
