/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */

import { action, flow, makeAutoObservable, remove } from 'mobx'
import type { Subscription } from 'rxjs'
import { Subject } from 'rxjs'

import type ById from '@src/lib/ById'
import StatefulPromise from '@src/lib/StatefulPromise'
import { chunk, insertAtIndex, removeAtIndex } from '@src/lib/collections'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import type { CodableCsvImportV2 } from '@src/service/model/CsvImportV2Model'
import CsvImportV2Model from '@src/service/model/CsvImportV2Model'
import type { CodableContactNoteReaction } from '@src/service/model/reactions/ContactNoteReactionModel'
import ContactNoteReactionModel, {
  isCodableContactNoteReaction,
} from '@src/service/model/reactions/ContactNoteReactionModel'
import makePersistable from '@src/service/storage/makePersistable'
import type ContactsClient from '@src/service/transport/contacts'
import type CsvImportV2Repository from '@src/service/worker/repository/CsvImportV2Repository'
import type { SharedContactSettingsRepository } from '@src/service/worker/repository/SharedContactSettingsRepository'

import type Service from '.'
import Collection from './collections/Collection'
import type {
  CodableGoogleContactSettings,
  GroupMembership,
  IContact,
  CodableSharedContactSettings,
  NoteModel,
} from './model'
import {
  Contact,
  Member,
  buffer,
  GoogleContactSettingsModel,
  SharedContactSettingsModel,
} from './model'
import { ContactTemplateItemModel } from './model/ContactTemplateItemModel'
import type { PageInfo } from './transport/lib/Paginated'
import type { GooglePeopleSyncProgressNotification } from './transport/websocket'
import type MainWorker from './worker/main'
import type {
  ContactGoogleSettingsRepository,
  ContactRepository,
  ContactTemplateItemRepository,
} from './worker/repository'

interface CsvImport {
  name: string
  userId: string
}

export default class ContactStore {
  readonly collection: PersistedCollection<Contact, ContactRepository>
  readonly template: PersistedCollection<
    ContactTemplateItemModel,
    ContactTemplateItemRepository
  >
  readonly googleContactSettings: PersistedCollection<
    GoogleContactSettingsModel,
    ContactGoogleSettingsRepository
  >
  settings: PersistedCollection<
    SharedContactSettingsModel,
    SharedContactSettingsRepository
  >
  /**
   * @deprecated will be used only for legacy csv imports that were made locally
   */
  csvImports: CsvImport[] = []
  /**
   * Part of the new csv import flow.
   * It will hold all the information about the csv import jobs
   * Includes deleted entries
   */
  csvImportsV2: PersistedCollection<CsvImportV2Model, CsvImportV2Repository>
  /**
   * A collection derived from csvImportsV2.
   * It will hold only the active csv import jobs
   */
  activeCsvImportsV2 = new Collection<CsvImportV2Model>({
    filter: (item) => !item.deletedAt && item.status !== 'incomplete',
  })
  loaded = false
  isFetchingContacts = false

  private byNumber: ById<Map<Contact['id'], Contact>> = {}
  private contactNumbers: ById<string[]> = {}

  private prevIndexedDbCount: number | null = null
  private pageInfo: PageInfo | null = null
  private lastFetchedAt: number | null = null
  private contactUpdate$ = new Subject<Contact>()
  private contactTemplateItemUpdate$ = new Subject<ContactTemplateItemModel>()

  private fetchCsvPromise = new StatefulPromise(this.handleFetchCsv.bind(this))

  fetchContactsPromise: StatefulPromise<
    Awaited<ReturnType<ContactsClient['fetch']>>,
    Parameters<ContactsClient['fetch']>
  >
  contactSharingSettingsPromise: StatefulPromise<
    Awaited<ReturnType<ContactsClient['settings']['fetch']>>,
    Parameters<ContactsClient['settings']['fetch']>
  >
  googleContactSettingsPromise: StatefulPromise<
    Awaited<ReturnType<ContactsClient['settings']['fetchGoogleSettings']>>,
    Parameters<ContactsClient['settings']['fetchGoogleSettings']>
  >

  constructor(
    private root: Service,
    private worker: MainWorker,
  ) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('contact'),
      classConstructor: () => new Contact(root),
    })
    this.template = new PersistedCollection({
      table: this.root.storage.table('contactTemplateItem'),
      classConstructor: () => new ContactTemplateItemModel(root.contact),
    })
    this.googleContactSettings = new PersistedCollection({
      table: this.root.storage.table('contactGoogleSettings'),
      classConstructor: () => new GoogleContactSettingsModel(this),
    })

    this.settings = new PersistedCollection({
      table: this.root.storage.table('contactSettings'),
      classConstructor: (json: CodableSharedContactSettings) =>
        new SharedContactSettingsModel(json),
    })

    this.csvImportsV2 = new PersistedCollection<CsvImportV2Model, CsvImportV2Repository>({
      table: this.root.storage.table('csvImports'),
      classConstructor: (json: CodableCsvImportV2) => new CsvImportV2Model(json),
    })

    this.activeCsvImportsV2.bind(this.csvImportsV2)

    makeAutoObservable(this, {})

    makePersistable<this, 'pageInfo' | 'lastFetchedAt'>(this, 'ContactStore', {
      pageInfo: root.storage.sync(),
      lastFetchedAt: root.storage.sync(),
    })

    this.fetchContactsPromise = new StatefulPromise(
      (query: Parameters<ContactsClient['fetch']>[0]) =>
        this.root.transport.contacts.fetch(query),
    )
    this.contactSharingSettingsPromise = new StatefulPromise(() =>
      this.root.transport.contacts.settings.fetch(),
    )
    this.googleContactSettingsPromise = new StatefulPromise(() =>
      this.root.transport.contacts.settings.fetchGoogleSettings(),
    )

    this.handleWebsocket()
    this.handleIndexByPhoneNumber()
    this.loadCsvImports()
  }

  get sharedContactSettings(): SharedContactSettingsModel | null {
    return this.settings.list[0]
  }

  set sharedContactSettings(value: Partial<SharedContactSettingsModel> | null) {
    const settings = this.settings.list[0]
    const defaultSharingIds = value?.defaultSharingIds

    if (settings && defaultSharingIds) {
      settings.defaultSharingIds = defaultSharingIds
    }
  }

  get defaultSharingIds(): readonly string[] {
    if (!this.sharedContactSettings?.defaultSharingIds) {
      return []
    }

    return this.root.workspace.filterDeletedGroupIds(
      this.sharedContactSettings.defaultSharingIds,
    )
  }

  get sortedTemplates() {
    return this.template.list.sort((i1, i2) => {
      if (i1.order == null) return 1
      if (i2.order == null) return -1
      return i1.order - i2.order
    })
  }

  onContactUpdate(callback: (contact: Contact) => void): Subscription {
    return this.contactUpdate$.subscribe(callback)
  }

  resyncGoogleContactSettings = (settings: CodableGoogleContactSettings) => {
    return this.root.transport.contacts.settings.resync(settings)
  }

  onTemplateItemUpdate(
    callback: (contact: ContactTemplateItemModel) => void,
  ): Subscription {
    return this.contactTemplateItemUpdate$.subscribe(callback)
  }

  get(id: string) {
    return this.collection.get(id)
  }

  getByNumber(number: string) {
    this.loadByNumber(number)
    return this.byNumber[number] ? Array.from(this.byNumber[number].values()) : []
  }

  getByNumberSorted(number: string) {
    // Without sorting it, the array could be "shuffled" by subsequent loads (for example the search reaction in `VoicePhoneNumberSelectorController`)
    // and return inconsistent results
    return this.getByNumber(number).sort(
      (a, b) => (a.createdAt ?? 0) - (b.createdAt ?? 0),
    )
  }

  loadAll = () => {
    return this.collection.performQuery((repo) => repo.all())
  }

  loadAllIfNecessary = () => {
    if (this.loaded) return
    this.loaded = true
    return this.loadAll()
  }

  loadByNumbers = (numbers: string[]) => {
    const load = numbers.filter((number) => !this.byNumber[number])
    return this.collection.performQuery((repo) => repo.getByPhoneNumbers(load))
  }

  loadByNumber = buffer(this.loadByNumbers)

  googleSync = (token: string, redirectUri = 'postmessage'): Promise<any> => {
    return this.root.transport.contacts.googleSync(token, redirectUri)
  }

  fetchMissing = async (): Promise<any> => {
    const self = this
    this.isFetchingContacts = true
    const shouldForceFetch = await this.getShouldForceFetch()

    if (shouldForceFetch || this.pageInfo?.hasNextPage !== false) {
      return this.fetchContactsPromise
        .run({
          limit: this.root.flags.getFlag('fetchContactsBatchLimit'),
          lastId: shouldForceFetch ? undefined : this.pageInfo?.endId,
        })
        .then(
          flow(function* (resp) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
            const contacts = yield self.load(resp.result)
            self.pageInfo = resp.pageInfo
            self.lastFetchedAt = Math.max(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
              ...contacts.map((c) => c.updatedAt),
              Number(self.lastFetchedAt) + 1,
            )
            self.isFetchingContacts = false
          }),
        )
        .then(() => this.fetchMissing())
    } else if (this.pageInfo?.hasNextPage === false && this.lastFetchedAt) {
      return this.fetchContactsPromise
        .run({
          since: new Date(this.lastFetchedAt),
          includeDeleted: true,
        })
        .then(
          flow(function* (resp) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
            const contacts = yield self.load(resp)
            self.lastFetchedAt = Math.max(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
              ...contacts.map((c) => c.updatedAt),
              Number(self.lastFetchedAt) + 1,
            )
            self.isFetchingContacts = false
          }),
        )
    }

    this.isFetchingContacts = false
  }

  async createBulk(contacts: Contact[]) {
    await this.collection.load(contacts)
    return Promise.all(
      chunk(contacts, 100).map((contacts) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        this.root.transport.contacts.createBulk(contacts.map((c) => c.toJSON())),
      ),
    )
  }

  update = (contact: Contact) => {
    contact.local = false
    this.collection.put(contact)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    return this.root.transport.contacts.update(contact.toJSON())
  }

  merge = (contact: Contact, withContacts: Contact[]) => {
    // TODO: offline support
    return this.root.transport.contacts.merge(
      contact.id,
      withContacts.map((c) => c.id),
    )
  }

  delete = async (contact: Contact) => {
    this.collection.delete(contact)
    if (!contact.local) {
      return this.root.transport.contacts.delete(contact.id)
    }
  }

  deleteBySource = async (source: string, sourceName: string) => {
    await this.loadAllIfNecessary()
    const ids = this.collection.list
      .filter((c) => c.source === source && c.sourceName === sourceName)
      .map((c) => c.id)
    return this.deleteBulk(ids)
  }

  deleteBySourceName = async (sourceName: string) => {
    await this.loadAllIfNecessary()
    const ids = this.collection.list
      .filter((c) => c.sourceName === sourceName)
      .map((c) => c.id)
    return this.collection.deleteBulk(ids)
  }

  deleteAll = () => {
    const ids = this.collection.list.map((c) => c.id)
    return this.deleteBulk(ids)
  }

  deleteBulk = (ids: string[]) => {
    if (!ids || ids.length === 0) return Promise.resolve()
    this.collection.deleteBulk(ids)
    return Promise.all(
      chunk(ids, 500).map((ids) => this.root.transport.contacts.deleteBulk(ids)),
    )
  }

  shareBulk = async (
    contactIds: string[],
    shareIds: string[],
    progress: (progress: number) => void,
  ) => {
    progress(0)
    const batches = chunk(contactIds, 250)
    for (let i = 0; i < batches.length; i++) {
      const ids = batches[i]
      await this.root.transport.contacts.shareBulk(ids, shareIds)
      progress((i + 1) / (batches.length - 1))
    }
  }

  putNote = (note: NoteModel) => {
    this.collection.put(note.contact)
    return this.root.transport.contacts.note.put(note.contact.id, note.toJSON())
  }

  deleteNote = (note: NoteModel) => {
    this.collection.put(note.contact)
    return this.root.transport.contacts.note.deleteNote(note.contact.id, note.id)
  }

  fetchTemplates = () => {
    this.template.performQuery((repo) => repo.all())
    return this.root.transport.contacts.template
      .fetch()
      .then(action((res) => this.template.load(res, { deleteOthers: true })))
  }

  updateTemplate = (template: ContactTemplateItemModel) => {
    template.local = false
    this.template.put(template)
    return this.root.transport.contacts.template
      .put(template.serialize())
      .then(this.template.load)
  }

  deleteTemplate = async (template: ContactTemplateItemModel) => {
    this.template.delete(template)
    if (!template.local) {
      return this.root.transport.contacts.template.delete(template.serialize())
    }
  }

  reorderTemplates = (from: number, to: number) => {
    const sorted = [...this.sortedTemplates]
    const item = sorted[from]
    insertAtIndex(removeAtIndex(sorted, from), item, to).map((t, order) => {
      t.update({ order })
    })
  }

  fetchGoogleContactSettings = () => {
    this.googleContactSettings.performQuery((repo) => repo.all())
    return this.googleContactSettingsPromise
      .run()
      .then((res) => this.googleContactSettings.load(res, { deleteOthers: true }))
  }

  fetchSettings = () => {
    this.settings.performQuery((repo) => repo.all())
    return this.contactSharingSettingsPromise.run().then((res) => {
      this.settings.load(res, { deleteOthers: true })
    })
  }

  private handleFetchCsv() {
    return this.root.transport.contacts.csv.getAllImports()
  }

  get fetchCsvStatus() {
    return this.fetchCsvPromise.status
  }

  async loadCsvImportsV2() {
    if (this.fetchCsvStatus === 'idle') {
      const response = await this.fetchCsvPromise.run()
      this.csvImportsV2.load(response)
    }
  }

  loadCsvImports() {
    this.worker.service.contact.getUniqueSources('csv').then(
      action((data) => {
        this.csvImports = data.map((item) => ({
          name: item.sourceName,
          userId: item.userId,
        }))
      }),
    )
  }

  updateSettings = (
    settings: Pick<CodableSharedContactSettings, 'defaultSharingIds'>,
  ) => {
    return this.root.transport.contacts.settings.put(settings)
  }

  deleteSettings = (id: string) => {
    this.googleContactSettings.delete(id)
    return this.root.transport.contacts.settings.delete(id)
  }
  addNoteReaction(
    contactId: string,
    noteId: string,
    reaction: CodableContactNoteReaction,
  ) {
    return this.root.transport.contacts.note.addReaction(contactId, noteId, reaction)
  }

  deleteNoteReaction(
    contactId: string,
    noteId: string,
    reaction: CodableContactNoteReaction,
  ) {
    return this.root.transport.contacts.note.deleteReaction(
      contactId,
      noteId,
      reaction.id,
    )
  }

  /**
   * Calculates the total number of unique members that are contained
   * in the list of entity ids.
   *
   * @param entityIds string[] - List of user, organization, and group ids
   */
  getUniqueMemberCountInEntities(entityIds: string[]) {
    const org = this.root.organization.current
    if (org && entityIds.includes(org.id)) {
      return this.root.member.collection.length
    }

    const userMembersIds = entityIds.filter((id) => id.startsWith('US'))
    const groupAndPhoneNumberMembersIds = entityIds
      .filter((id) => id.startsWith('GR'))
      .reduce((result, groupId) => {
        const phoneNumberOrGroup =
          this.root.workspace.activeGroups.find((group) => group.id === groupId) ??
          this.root.phoneNumber.collection.list.find((ph) => ph.groupId === groupId)

        const membersIds: string[] =
          phoneNumberOrGroup?.members.map((member: Member | GroupMembership) => {
            if (member instanceof Member) {
              return member.id
            }

            return member.userId
          }) ?? []
        return [...result, ...membersIds]
      }, [] as string[])
    return [...new Set([...userMembersIds, ...groupAndPhoneNumberMembersIds])].length
  }

  submitCsvImportMetadata = (
    metadata: Parameters<typeof this.root.transport.contacts.csv.import>[0],
  ) => {
    return this.root.transport.contacts.csv.import(metadata)
  }

  updateCsvImportStatus = (
    id: Parameters<typeof this.root.transport.contacts.csv.importStatus>[0],
    jobMeta: Parameters<typeof this.root.transport.contacts.csv.importStatus>[1],
  ) => {
    return this.root.transport.contacts.csv.importStatus(id, jobMeta)
  }

  submitCsvFile = (file: File, url: string) => {
    return this.root.transport.contacts.csv.submitFile(file, url)
  }

  /**
   * Deletes a CSV Import entry from the collection,
   * as well as all the contacts associated with it.
   *
   * @param id string - csv import identifier
   */
  deleteCsvImport = (id: string) => {
    const csvImport = this.csvImportsV2.get(id)

    if (csvImport) {
      csvImport.deletedAt = new Date().toISOString()
      this.csvImportsV2.put(csvImport)
    }

    return this.root.transport.contacts.csv.deleteImport(id)
  }

  submitCsvImport = async (
    file: File,
    metadata: Parameters<typeof this.submitCsvImportMetadata>[0],
    customFields: ContactTemplateItemModel[],
  ) => {
    const [response] = await Promise.all([
      // submit csv job metadata
      await this.submitCsvImportMetadata(metadata),
      // Create template items if needed
      ...customFields.map((field) => this.updateTemplate(field)),
    ])

    if (!response || !file) {
      return
    }

    await this.submitCsvFile(file, response.s3SignedUrl)

    this.updateCsvImportStatus(response.id, { status: 'uploaded', retries: 0 })
  }

  private async getShouldForceFetch() {
    const contactTable: ContactRepository = this.root.storage.table('contact')
    const count = await contactTable.count()

    // A count of 0 means indexedDB is empty and we should attempt to
    // refetch all the data, but only if it wasn't previously 0 to
    // avoid an infinite loop of refetching
    const shouldForceFetch = this.prevIndexedDbCount !== 0 && count === 0
    this.prevIndexedDbCount = count

    return shouldForceFetch
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'reaction-update':
          if (isCodableContactNoteReaction(data.reaction)) {
            return this.handleContactNoteReactionUpdate(data.reaction)
          }
          break
        case 'reaction-delete':
          if (isCodableContactNoteReaction(data.reaction)) {
            return this.handleContactNoteReactionDelete(data.reaction)
          }
          break
        case 'contact-update':
        case 'contact-note-update':
        case 'contact-note-delete': {
          this.updateCsvImportSourcesIfNeeded(data.contact)
          this.collection.load(data.contact)
          const contact = this.collection.get(data.contact.id)
          if (contact) {
            this.contactUpdate$.next(contact)
          }
          break
        }

        case 'contact-delete':
          return this.collection.delete(data.contact.id)

        case 'bulk-operation-complete': {
          if (data.collection === 'contacts') {
            this.fetchMissing()
          }
          return
        }

        case 'template-update': {
          this.template.load(data.template)
          const template = this.template.get(data.template.id)
          if (template) {
            this.contactTemplateItemUpdate$.next(template)
          }
          return
        }
        case 'template-delete':
          return this.template.delete(data.template.id)
        case 'google-people-sync-progress':
          return this.handleGooglePeopleSyncProgressNotification(data)

        case 'contact-settings-update':
          return this.handleContactSettingsUpdate(data.settings)
        case 'contact-settings-delete':
          return this.handleContactSettingsDelete(data.settings)
        case 'csv-import-processed':
          return this.handleCsvImportProcessed(data.data)
      }
    })
  }

  private handleCsvImportProcessed(value: CodableCsvImportV2) {
    this.csvImportsV2.put(new CsvImportV2Model(value))
  }

  private handleContactNoteReactionUpdate(value: CodableContactNoteReaction) {
    const contact = this.collection.get(value.contactId)
    const note = contact?.notes.find((note) => note.id === value.noteId)

    if (note) {
      const reaction = note.reactions.find((reaction) => reaction.id === value.id)

      if (reaction) {
        reaction.deserialize(value)
      } else {
        note.reactions.push(new ContactNoteReactionModel(value))
      }
    }
  }

  private handleContactNoteReactionDelete(value: CodableContactNoteReaction) {
    const contact = this.collection.get(value.contactId)
    const note = contact?.notes.find((note) => note.id === value.noteId)

    if (!note) {
      return
    }

    const index = note.reactions.findIndex((reaction) => reaction.id === value.id)

    if (index >= 0) {
      note.reactions = removeAtIndex(note.reactions, index)
    }
  }

  private updateCsvImportSourcesIfNeeded(contact: IContact) {
    if (
      contact.source === 'csv' &&
      contact.sourceName &&
      contact.userId &&
      !this.csvImports.some((source) => source.name === contact.sourceName)
    ) {
      this.csvImports.push({
        name: contact.sourceName,
        userId: contact.userId,
      })
    }
  }

  private handleContactSettingsUpdate(settings: CodableSharedContactSettings) {
    const item = this.settings.find((item) => item.id === settings.id)
    if (item) {
      item.deserialize(settings)
      this.settings.put(item)
    }
  }

  private handleContactSettingsDelete(settings: CodableSharedContactSettings) {
    this.settings.delete(settings.id)
  }

  private handleGooglePeopleSyncProgressNotification(
    msg: GooglePeopleSyncProgressNotification,
  ) {
    const settings = this.googleContactSettings.list.find(
      (s) => s.source === msg.status.source,
    )
    if (!settings) return
    settings.resyncStatus = msg.status.state
  }

  /**
   * As contacts are added/updated/deleted from the collection, this
   * function keeps map of phone numbers to contacts for fast lookup
   */
  private handleIndexByPhoneNumber() {
    this.collection.observe(
      action((event) => {
        if (event.type === 'put') {
          event.objects.forEach((contact) => {
            this.contactNumbers[contact.id] ??= []
            const numbers = this.contactNumbers[contact.id]
            numbers.forEach((number) => {
              this.byNumber[number]?.delete(contact.id)
            })
            contact.phoneNumbers.forEach((item) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
              const number = item.value
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
              this.byNumber[number] ??= new Map()
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
              if (!this.byNumber[number].has(contact.id)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
                this.byNumber[number].set(contact.id, contact)
              }
            })
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
            this.contactNumbers[contact.id] = contact.phoneNumbers.map((i) => i.value)
          })
        } else if (event.type == 'delete') {
          event.objects.forEach((object) => {
            const numbers = this.contactNumbers[object.id]
            numbers?.forEach((number) => {
              this.byNumber[number]?.delete(object.id)
            })
            remove(this.contactNumbers, object.id)
          })
        }
      }),
    )
  }

  load(contacts: any) {
    if (!contacts) return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    this.collection.deleteBulk(contacts.filter((c) => c.deletedAt))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    return this.collection.load(contacts.filter((c) => !c.deletedAt))
  }
}
