import { useCallback, useEffect, useState } from 'react'

interface UseAsyncScriptOptions {
  scriptCreator: () => HTMLScriptElement
  getInitialState?: () => boolean
  position?: 'head' | 'body'
  disabled?: boolean
}

export default function useAsyncScript({
  scriptCreator,
  getInitialState,
  position = 'head',
  disabled = false,
}: UseAsyncScriptOptions) {
  const [loaded, setLoaded] = useState(getInitialState ?? false)

  const appendScript = useCallback(
    (script: HTMLScriptElement) => {
      if (position === 'head') {
        document.head.appendChild(script)
      }

      document.body.appendChild(script)
    },
    [position],
  )

  useEffect(() => {
    if (disabled || loaded) return

    const script = scriptCreator()

    script.onload = () => {
      setLoaded(true)
    }

    appendScript(script)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [scriptCreator, loaded])

  return { loaded }
}
