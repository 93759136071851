import cx from 'classnames'

import { isMac } from '@src/config'

import * as styles from './Header.css'

type HeaderProps = React.HTMLProps<HTMLDivElement>

const Header = function ({ className, ...props }: HeaderProps) {
  return (
    <div
      {...props}
      className={cx(styles.header, className, { [styles.headerMac]: isMac })}
    />
  )
}

export default Header

export const HeaderSpacer = function () {
  return <div className={styles.spacer} />
}
