import type { PhoneNumber } from '@src/service/model'

import type Heap from './Heap'

/**
 *
 */

interface TollFreeRegistrationError {
  errorMessage: string
  phoneNumberId: PhoneNumber['id']
}

export default class TrustAnalyticsStore {
  constructor(private readonly heap: Heap) {}

  /**
   * Heap event fires when a user is shown the toll-free message block
   */
  tollFreeMessageBlockViewed(
    messageBlockVariation: 'draft_or_rejected' | 'submitted',
  ): void {
    this.heap.track('viewed_toll_free_message_block', {
      messageBlockVariation,
    })
  }

  /**
   * Heap event fires when the user clicks the link that takes them to the registration flow  (ie. opens the typeform, eventually the in product registration flow)
   */
  tollFreeMessageRegistrationClicked(): void {
    this.heap.track('clicked_toll_free_message_registration_cta')
  }

  /**
   * Heap event fires when the user clicks a link that takes them to the help docs on any toll-free message block callout
   */
  tollFreeMessageBlockHelpClicked(): void {
    this.heap.track('clicked_toll_free_message_block_help_cta')
  }

  /**
   * Heap event fires when the user starts the tfn registration flow
   */
  tollFreeRegistrationFlowStarted(): void {
    this.heap.track('started_toll_free_registration_flow')
  }

  /**
   * Heap event fires when the user submits a toll-free registration
   */
  tollFreeRegistrationSubmitted(): void {
    this.heap.track('submitted_toll_free_registration')
  }

  /**
   * Heap event fires when the user submits a toll-free registration and there is an error
   */
  tollFreeRegistrationSubmittedError({
    errorMessage,
    phoneNumberId,
  }: TollFreeRegistrationError): void {
    this.heap.track('submitted_toll_free_registration_error', {
      phoneNumberId,
      errorMessage,
    })
  }

  /**
   * Heap event fires when the user saves a draft toll-free registration
   */
  tollFreeRegistrationSaveDraft(): void {
    this.heap.track('saved_draft_toll_free_registration')
  }

  /**
   * Heap event fires when the user saves a draft toll-free registration and there is an error
   */
  tollFreeRegistrationSaveDraftError({
    errorMessage,
    phoneNumberId,
  }: TollFreeRegistrationError): void {
    this.heap.track('saved_draft_toll_free_registration_error', {
      phoneNumberId,
      errorMessage,
    })
  }

  /**
   * Fires when a caller ID name has been set as the default
   */
  defaultCallerIdSet(): void {
    this.heap.track('default_caller_id_set')
  }

  /**
   * Fires when a caller ID name has been deleted
   */
  callerIdDeleted(): void {
    this.heap.track('caller_id_deleted')
  }

  /**
   * Fires when a caller ID name has been assigned to a phone number
   */
  callerIdAssigned(): void {
    this.heap.track('caller_id_assigned')
  }

  /**
   * Fires when a caller ID name is created
   */
  callerIdCreated(): void {
    this.heap.track('caller_id_created')
  }

  /**
   * Fires when the user starts to create a caller ID name
   */
  callerIdCreationStarted(): void {
    this.heap.track('caller_id_creation_started')
  }

  /**
   * Fires when the user starts to resubmit a caller ID name
   */
  callerIdResubmissionStarted(): void {
    this.heap.track('caller_id_resubmission_started')
  }

  /**
   * Fires when the user resubmits a caller ID name
   */
  callerIdResubmitted(): void {
    this.heap.track('caller_id_resubmitted')
  }

  /**
   * Fires when the user starts to edit a caller ID name
   */
  callerIdEditStarted(): void {
    this.heap.track('caller_id_edit_started')
  }

  /**
   * Fires when the user edits a caller ID name
   */
  callerIdEdited(): void {
    this.heap.track('caller_id_edited')
  }
}
