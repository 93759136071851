import difference from 'lodash/fp/difference'
import intersection from 'lodash/fp/intersection'
import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import type Nullable from '@src/lib/Nullable'
import isNonNull from '@src/lib/isNonNull'
import uuid from '@src/lib/uuid'

import type Service from '..'

import type { Model, Member, CodableMessageMedia, ParticipantModel } from '.'
import { ConversationModel, MessageMediaModel, isCodableMessageMedia } from '.'

export type TerminalCondition = 'Regardless' | 'IfNoReply'

interface ScheduledMessageBody {
  to: { phoneNumber: string }[]
  activityId: string
  conversationId: string
  conversationName: string | null
  messageId: string | null
  from: {
    phoneNumberId: string
    directNumberId?: string | null
  }
  message: {
    clientId?: string | null
    body: string
    mediaUrl: (string | CodableMessageMedia)[]
    createdBy: string
  }
}

export type CodableScheduledMessage = Nullable<{
  id: string
  userId: string
  body: ScheduledMessageBody
  terminalCondition: TerminalCondition
  path: string
  sendAttempts: number
  createdAt: number | string
  updatedAt: number | string
  sendAt: number | string
  cancelledAt: number | string
  messageMedia: CodableMessageMedia[]
  cancelReason?: string
}>

class ScheduledMessageModel implements CodableScheduledMessage, Model {
  id = `SM${uuid()}`.replace(/-/g, '')
  userId: string | null = null
  body: ScheduledMessageBody | null = null
  terminalCondition: TerminalCondition = 'IfNoReply'
  path: string | null = null
  sendAttempts: number | null = null
  createdAt: number | null = null
  updatedAt: number | null = null
  sendAt: number | null = null
  cancelledAt: number | null = null
  messageMedia: MessageMediaModel[] = []
  cancelReason: string | null = null

  constructor(
    private root: Service,
    attrs: CodableScheduledMessage,
  ) {
    this.deserialize(attrs)

    makeAutoObservable(this, {})
  }

  get conversation(): ConversationModel | null {
    const conversationId = this.body?.conversationId
    if (!conversationId) return null
    return this.root.conversation.collection.get(conversationId) ?? null
  }

  get isFromMe(): boolean {
    if (!this.body) return false
    return this.body.message.createdBy === this.root.user.current?.asMember?.id
  }

  get from(): Member | null {
    if (this.isFromMe) return this.root.user.current?.asMember ?? null

    const conversation = this.conversation

    if (!conversation) return null

    const phoneNumber = this.root.phoneNumber.collection.get(conversation.phoneNumberId)

    if (!this.body || !phoneNumber) return null

    const member =
      phoneNumber.members.find((member) => member.id === this.body?.message.createdBy) ??
      null

    return member
  }

  get to(): ParticipantModel[] {
    if (!this.conversation || !this.body) return []
    const receivingNumbers = this.body.to.map((item) => item.phoneNumber)
    return (
      this.conversation.participants
        .filter(isNonNull)
        .filter((participant) => receivingNumbers.includes(participant.phoneNumber)) ?? []
    )
  }

  get recipientNames(): string {
    return this.to.map((participant) => participant.name).join(', ')
  }

  /**
   * Checks if there's media. Otherwise check the media urls array and
   * make media from there if there's any. This code path should be ran
   * once.
   */
  get media(): MessageMediaModel[] {
    if (this.messageMedia.length > 0) return this.messageMedia
    if (this.body && this.body.message.mediaUrl.length > 0) {
      this.setMediaFromMediaUrls(this.body.message.mediaUrl)
      return this.messageMedia
    }
    return []
  }

  deserialize({
    createdAt,
    updatedAt,
    sendAt,
    cancelledAt,
    messageMedia,
    ...json
  }: CodableScheduledMessage) {
    Object.assign(this, json)

    this.createdAt = createdAt ? parseDate(createdAt) : null

    this.updatedAt = updatedAt ? parseDate(updatedAt) : null

    this.sendAt = sendAt ? parseDate(sendAt) : null

    this.cancelledAt = cancelledAt ? parseDate(cancelledAt) : null

    if (messageMedia) {
      this.setMediaFromMessageMedia(messageMedia)
    }

    return this
  }

  serialize(): CodableScheduledMessage {
    return {
      id: this.id,
      userId: this.userId,
      body: toJS(this.body),
      terminalCondition: this.terminalCondition,
      path: this.path,
      sendAttempts: this.sendAttempts,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      sendAt: this.sendAt,
      cancelledAt: this.cancelledAt,
      cancelReason: this.cancelReason,
      messageMedia: this.messageMedia?.map((media) => media.serialize()),
    }
  }

  setMediaFromMessageMedia(messageMedia: CodableMessageMedia[]) {
    if (
      !Array.isArray(messageMedia) ||
      (messageMedia.length > 0 && !messageMedia.every(isCodableMessageMedia))
    ) {
      return
    }

    this.messageMedia = messageMedia.map((media) => new MessageMediaModel(media)) ?? []

    if (this.body) {
      this.body.message.mediaUrl = this.messageMedia
        .map((media) => media.url)
        .filter(isNonNull)
    }
  }

  /**
   * Synchronizes media updates. Checks what media is kept, which is new and which should be deleted.
   * This is called:
   * - on fetching scheduled messages
   * - on socket event updates
   * - on accessing `media` getter
   */
  setMediaFromMediaUrls(media: (CodableMessageMedia | string)[]) {
    const mediaMap: Map<string, MessageMediaModel> = new Map()
    const newMediaMap: Map<string, CodableMessageMedia> = new Map()

    for (const messageMedia of this.messageMedia) {
      if (!messageMedia.url) continue
      mediaMap.set(messageMedia.url, messageMedia)
    }

    const localMedia = this.messageMedia.map((media) => media.url).filter(isNonNull)
    const remoteMedia = media
      .map((item) => {
        if (!isCodableMessageMedia(item)) return item
        if (item.url !== null) {
          newMediaMap.set(item.url, item)
        }
        return item.url
      })
      .filter(isNonNull)
    const mediaToKeep = intersection(remoteMedia, localMedia)
    const newMedia = difference(remoteMedia, mediaToKeep)
    const staleMedia = difference(localMedia, mediaToKeep)

    for (const staleMediaUrl of staleMedia) {
      mediaMap.delete(staleMediaUrl)
    }

    for (const newMediaUrl of newMedia) {
      const mediaItem = newMediaMap.get(newMediaUrl) ?? newMediaUrl
      const mediaAttributes: Partial<CodableMessageMedia> = isCodableMessageMedia(
        mediaItem,
      )
        ? mediaItem
        : { url: mediaItem }
      const media = new MessageMediaModel(mediaAttributes)
      mediaMap.set(newMediaUrl, media)
    }

    this.messageMedia = [...mediaMap.values()]
  }

  clone() {
    return new ScheduledMessageModel(this.root, this.serialize())
  }

  /**
   * Makes a conversation object based on the scheduled message data
   */
  makeConversation(): ConversationModel | null {
    const id = this.body?.conversationId
    const phoneNumber = this.body?.to.map((pn) => pn.phoneNumber).join(',')
    const phoneNumberId = this.body?.from.phoneNumberId
    const lastActivityAt = this.createdAt ?? undefined
    const attributes = {
      id,
      phoneNumber,
      phoneNumberId,
      lastActivityAt,
      isNew: false,
    }

    if (Object.values(attributes).some((attribute) => !isNonNull(attribute))) {
      return null
    }

    const conversation = new ConversationModel(this.root, attributes)

    return conversation
  }

  save() {
    this.root.scheduledMessage.collection.put(this)
  }
}

export default ScheduledMessageModel
