import dayjs from 'dayjs'
import differenceWith from 'lodash/fp/differenceWith'
import { makeAutoObservable } from 'mobx'
import type { Subscription } from 'rxjs'

import { parseDate } from '@src/lib'
import { DisposeBag } from '@src/lib/dispose'
import PersistedCollection from '@src/service/collections/PersistedCollection'

import type Service from '.'
import type ParticipantModel from './model/ParticipantModel'
import ScheduledMessageModel, {
  type CodableScheduledMessage,
} from './model/ScheduledMessageModel'
import type { ConversationModel } from './model/conversation'
import type {
  ScheduledMessageDeleteNotification,
  ScheduledMessageUpdateNotification,
} from './transport/websocket'
import type { ScheduledMessageRepository } from './worker/repository'
import { SCHEDULED_MESSAGE_TABLE_NAME } from './worker/repository'

export default class ScheduledMessageStore {
  readonly collection: PersistedCollection<
    ScheduledMessageModel,
    ScheduledMessageRepository
  >

  private readonly disposeBag = new DisposeBag()

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table(SCHEDULED_MESSAGE_TABLE_NAME),

      classConstructor: (json: CodableScheduledMessage) =>
        new ScheduledMessageModel(root, json),
      filter: (item) => {
        return !!item.cancelledAt || (!!item.sendAt && item.sendAt >= Date.now())
      },
      compare: (a, b) => {
        if (a.sendAt && b.sendAt) {
          return a.sendAt - b.sendAt
        }

        return 0
      },
    })

    makeAutoObservable(this, {})

    const socketSubscription = this.subscribeToWebSockets()
    this.disposeBag.add(socketSubscription)
  }

  /**
   * Creates or updates an scheduled message remotely and locally
   */
  async send(scheduledMessage: CodableScheduledMessage): Promise<ScheduledMessageModel> {
    if (!scheduledMessage?.body)
      return Promise.reject('Schedule message body was not provided')

    if (!scheduledMessage?.sendAt)
      return Promise.reject('Schedule message send date was not provided')

    const to = scheduledMessage.body.to.map((item) => item.phoneNumber).join(',')

    const mediaUrl = scheduledMessage.messageMedia ?? []

    const response = await this.root.transport.communication.scheduledMessages.send({
      id: scheduledMessage.id ?? undefined,
      phoneNumberId: scheduledMessage.body.from.phoneNumberId,
      to,
      body: scheduledMessage.body.message.body,
      mediaUrl,
      activityId: scheduledMessage.body.activityId,
      conversationId: scheduledMessage.body.conversationId,
      sendAt: dayjs(scheduledMessage.sendAt).toISOString(),
      terminalCondition: scheduledMessage.terminalCondition ?? 'IfNoReply',
    })

    const scheduledMessageModel = new ScheduledMessageModel(this.root, response.message)

    if (response.message.id) {
      scheduledMessageModel.id = response.message.id
    }

    scheduledMessageModel.createdAt = response.message.createdAt
      ? parseDate(response.message.createdAt)
      : null

    scheduledMessageModel.save()

    return scheduledMessageModel
  }

  getAll() {
    return this.collection.performQuery((repo) => repo.all())
  }

  /**
   * Fetches and stores scheduled messages that belong to a `phoneNumerId`
   *
   * Deletes local scheduled messages that are not present on the response and creates
   * conversations for those scheduled messages that do not have a conversation relationship
   */
  async getByPhoneNumberId(phoneNumberId: string) {
    const response = await this.root.transport.communication.scheduledMessages.list({
      phoneNumberId,
    })
    this.deletePhoneNumberStaleScheduledMessages(phoneNumberId, response)
    const scheduledMessages = await this.root.scheduledMessage.collection.load(response)
    this.syncMedia(scheduledMessages)
    this.createPhoneNumberEmptyConversations(scheduledMessages)
    return scheduledMessages
  }

  /**
   * Deletes scheduled message remotely and locally
   */
  async delete(scheduledMessage: ScheduledMessageModel) {
    const response = await this.root.transport.communication.scheduledMessages.delete({
      id: scheduledMessage.id,
    })

    this.root.scheduledMessage.collection.delete(scheduledMessage)

    this.deleteConversationIfNecessary(scheduledMessage)

    return response
  }

  findAllByConversation(conversation: ConversationModel) {
    return this.findAllByConversationId(conversation.id)
  }

  findAllByParticipant(participant: ParticipantModel) {
    return participant.id
      ? this.findAllByParticipantId(participant.id)
      : this.findAllByPhoneNumber(participant.phoneNumber)
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  private findAllByConversationId(conversationId: string) {
    return this.collection.list.filter(
      (scheduledMessage) => scheduledMessage.body?.conversationId === conversationId,
    )
  }

  private findAllByParticipantId(participantId: string) {
    return this.collection.list.filter((scheduledMessage) =>
      scheduledMessage.to.some((to) => to.id === participantId),
    )
  }

  private findAllByPhoneNumber(phoneNumber: string) {
    return this.collection.list.filter((scheduledMessage) =>
      scheduledMessage.to.some((to) => to.phoneNumber === phoneNumber),
    )
  }

  private deleteConversationByIdIfNecessary(scheduledMessageId: string) {
    const scheduledMessage = this.collection.get(scheduledMessageId)
    if (!scheduledMessage) return
    this.deleteConversationIfNecessary(scheduledMessage)
  }

  private deleteConversationIfNecessary(scheduledMessage: ScheduledMessageModel) {
    if (!scheduledMessage.conversation) return
    const scheduledMessagesOfConversation = this.findAllByConversation(
      scheduledMessage.conversation,
    )
    if (scheduledMessagesOfConversation.length > 0) return
    const conversation = this.root.conversation.collection.get(
      scheduledMessage.conversation.id,
    )
    if (conversation && (!conversation.isNew || conversation.lastActivityAt)) return
    conversation?.delete()
  }

  private subscribeToWebSockets(): Subscription {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'scheduled-message-update':
          return this.handleScheduledMessageUpdate(data)
        case 'scheduled-message-delete':
          return this.handleScheduledMessageDelete(data)
      }
    })
  }

  /**
   * Handles `scheduled-message-update` socket event
   *
   * Finds scheduled message locally based on event id, if found the scheduled message
   * gets updated, if not gets created. Syncs media and finally creates an empty
   * conversation if no conversation present in such scheduled message.
   */
  private handleScheduledMessageUpdate(message: ScheduledMessageUpdateNotification) {
    const notificationMessage = message.message

    if (!notificationMessage.id || !this.collection) return

    let scheduledMessage = this.collection.get(notificationMessage.id)

    if (scheduledMessage) {
      scheduledMessage.deserialize(notificationMessage)
    } else {
      scheduledMessage = new ScheduledMessageModel(this.root, notificationMessage)
    }

    if (notificationMessage.body) {
      scheduledMessage.setMediaFromMediaUrls(notificationMessage.body.message.mediaUrl)
    }

    scheduledMessage.save()

    this.createPhoneNumberEmptyConversations([scheduledMessage])
  }

  /**
   * Handles `scheduled-message-delete` socket event
   *
   * Deletes local scheduled message based on the event scheduled message id
   */
  private handleScheduledMessageDelete(message: ScheduledMessageDeleteNotification) {
    const scheduledMessage = message.message

    if (!scheduledMessage.id || !this.collection) return

    this.collection.delete(scheduledMessage.id)

    this.deleteConversationByIdIfNecessary(scheduledMessage.id)
  }

  /**
   * Deletes persisted scheduled messages that are not present on the API response
   * @param phoneNumberId
   * @param scheduledMessages
   */
  private deletePhoneNumberStaleScheduledMessages(
    phoneNumberId: string,
    scheduledMessages: CodableScheduledMessage[],
  ): void {
    const storedMessages = this.root.scheduledMessage.collection?.list.filter(
      (message) => message.body?.from.phoneNumberId === phoneNumberId,
    )
    const staleStoredMessages = differenceWith(
      (objectA, objectB) => objectA.id === objectB.id,
      storedMessages,
      scheduledMessages,
    )
    this.root.scheduledMessage.collection.deleteBulk(staleStoredMessages)
  }

  /**
   * For every scheduled message create a conversation if no local conversation
   * is found
   * @param scheduledMessages
   */
  private createPhoneNumberEmptyConversations(
    scheduledMessages: ScheduledMessageModel[],
  ) {
    for (const scheduledMessage of scheduledMessages) {
      if (scheduledMessage.conversation === null) {
        const newConversation = scheduledMessage.makeConversation()
        if (!newConversation) continue
        newConversation.save()
      }
    }
  }

  /**
   * Syncs media from remote source to local
   */
  private syncMedia(scheduledMessages: ScheduledMessageModel[]) {
    for (const scheduledMessage of scheduledMessages) {
      if (
        scheduledMessage.messageMedia.length &&
        scheduledMessage.body?.message.mediaUrl.length
      ) {
        scheduledMessage.setMediaFromMediaUrls(scheduledMessage.body.message.mediaUrl)
        scheduledMessage.save()
      }
    }
  }
}
