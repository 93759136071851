import Dexie from 'dexie'

const name = 'openphone-v3.0.0'

export default function createDatabase() {
  const indexedDb = new Dexie(name)
  indexedDb.version(1).stores({
    activity: '&id,conversationId,createdAt',
    blocklist: '&id',
    contact: '&id,*_phoneNumbers',
    contactSettings: '&id',
    contactTemplateItem: '&id',
    conversation: '&id,phoneNumber,phoneNumberId,directNumberId,lastActivityAt',
    directNumber: '&id',
    entityPhoneNumber: '&id',
    integration: '&id',
    integrationContact: '&id,*identifiers',
    kv: '',
    member: '&id',
    phoneNumber: '&id',
    presence: '&userId',
    snippet: '&id',
  })
  indexedDb.version(2).stores({
    transaction: '&id',
  })
  indexedDb.version(3).stores({
    alert: '&id',
  })
  indexedDb.version(4).stores({
    contact: '&id,*_phoneNumbers,sortName',
    conversation:
      '&id,phoneNumber,phoneNumberId,directNumberId,lastActivityAt,unreadCount',
  })
  indexedDb.version(5).stores({
    groups: '&id',
    webhooks: '&id',
  })
  indexedDb.version(6).stores({
    contactGoogleSettings: '&id',
  })
  indexedDb.version(7).stores({
    cnam: '&id',
  })
  indexedDb.version(8).stores({
    contact: '&id,*_phoneNumbers,sortName,[source+sourceName]',
  })
  indexedDb.version(9).stores({
    contact: '&id,*_phoneNumbers,sortName,[source+sourceName+userId]',
  })
  indexedDb.version(10).stores({
    scheduledMessage: '&id,body.conversationId,body.activityId',
  })
  indexedDb.version(11).stores({
    portRequest: '&id',
  })
  indexedDb.version(12).stores({
    capability: '&type',
  })
  indexedDb.version(13).stores({
    search: '&id',
  })
  indexedDb.version(14).stores({
    ringOrder: '&id,&phoneNumberId',
  })
  indexedDb.version(15).stores({
    csvImports: '&id',
  })
  indexedDb.version(16).stores({
    tollFreeRegistration: '&id',
  })
  indexedDb.version(17).stores({
    activity: '&id,conversationId,createdAt,[conversationId+createdAt]',
  })
  indexedDb.version(18).stores({
    callFallbackConfig: '&id,&phoneNumberId',
  })
  indexedDb.version(19).stores({
    tollFreeRegistrationLite: '&id',
  })

  return indexedDb
}
