import { useAppStore } from '@src/app/context'

interface UseAsyncCopyToClipboard {
  copyString: string
  successMessage: string
  errorMessage?: string
}

const useAsyncCopyToClipboard = () => {
  const { toast } = useAppStore()

  return async ({
    copyString,
    successMessage,
    errorMessage = 'Failed to copy to clipboard',
  }: UseAsyncCopyToClipboard) => {
    try {
      await navigator.clipboard.writeText(copyString)
      toast.show({ message: successMessage })
    } catch (error) {
      toast.showError(errorMessage)
    }
  }
}

export default useAsyncCopyToClipboard
