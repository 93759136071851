import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import type { MouseEvent, ReactNode } from 'react'

import { useBanner, useBannerCounter } from '@src/app/banner'
import type { BannerKey } from '@src/app/banner'
import IconButton from '@src/component/IconButton'
import { isMac } from '@src/config'
import { XIcon } from '@ui/icons/tint/20/general'

import * as styles from './Banner.css'

type ColorVariants = 'purple' | 'green' | 'blue' | 'yellow' | 'orange' | 'red'
export interface BannerProps {
  id?: BannerKey
  onClick?: () => void
  type: 'browser notifications' | 'outage' | 'impersonation' | ColorVariants
  isDismissible?: boolean
  className?: string
  children: ReactNode
}

function Banner({
  id,
  onClick,
  children,
  className,
  type,
  isDismissible = true,
}: BannerProps) {
  const { isDismissed, dismiss } = useBanner(id)

  useBannerCounter(!isDismissed)

  const handleClose = (event: MouseEvent) => {
    event.stopPropagation()
    dismiss()
  }

  if (isDismissed) {
    return null
  }

  return (
    <div
      className={cx(
        styles.root({
          type,
          hasAction: Boolean(onClick),
          isMacApp: isMac,
        }),
        className,
      )}
      onClick={onClick}
    >
      {children}
      {isDismissible ? (
        <IconButton
          rounded
          opaque
          variant="transparent"
          hoverColor="white"
          size={24}
          className={styles.close}
          icon={<XIcon />}
          onClick={handleClose}
        />
      ) : null}
    </div>
  )
}

export default observer(Banner)
