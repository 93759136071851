import { observer } from 'mobx-react-lite'
import { lazy, Suspense } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import Callback from '@src/app/Callback'
import StripeCallback from '@src/app/StripeCallback'
import ThirdPartyAuthorize from '@src/app/ThirdPartyAuthorize'
import ConnectedAppSkeleton from '@src/app/components/ConnectedAppSkeleton'
import { useAppStore } from '@src/app/context'
import { useEmitFirstRender } from '@src/app/electron'
import Login from '@src/app/login'
import NativeLogin from '@src/app/login/NativeLogin'

import AuthGuard from './AuthGuard'
import Redirect from './Redirect'
import RouterAnalytics from './RouterAnalytics'
import VerificationRoute from './VerificationRoute'

const Onboarding = lazy(() => import('@src/app/onboarding'))
const LoggedInRoot = lazy(() => import('@src/app/LoggedInRoot'))

const Router = () => {
  const app = useAppStore()

  useEmitFirstRender()

  return (
    <>
      <RouterAnalytics />
      <Suspense fallback={<ConnectedAppSkeleton />}>
        <Routes>
          <Route path="native/login/*" element={<NativeLogin />} />
          <Route path="verification/*" element={<VerificationRoute />} />
          <Route
            path="*"
            element={
              app.isLoggedIn ? (
                <AuthGuard>
                  <Outlet />
                </AuthGuard>
              ) : (
                <Login />
              )
            }
          >
            <Route path="callback/stripe/*" element={<StripeCallback />} />
            <Route path="callback/:provider/*" element={<Callback />} />
            <Route path="authorize/*" element={<ThirdPartyAuthorize />} />
            <Route path="onboarding/*" element={<Onboarding />} />
            <Route
              path="*"
              element={
                app.storesLoaded && app.needsOnboarding ? (
                  <Redirect to="/onboarding" />
                ) : (
                  <LoggedInRoot />
                )
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </>
  )
}

export default observer(Router)
