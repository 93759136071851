import cx from 'classnames'
import { observer } from 'mobx-react-lite'

import { useAppStore } from '@src/app/context'
import Snackbar, { SnackbarContent } from '@src/component/Snackbar'
import Button, { DangerButton } from '@src/component/button'
import useKeyboardShortcuts from '@src/lib/hooks/useKeyboardShortcuts'
import { LoadingIndicator } from '@ui/LoadingIndicator'
import Typography from '@ui/Typography'
import { ThemeProvider, themeClassNames, useTheme } from '@ui/theme'

import * as styles from './Toast.css'
import type { ToastAction } from './ToastUiStore'

const Toast = () => {
  const { toast } = useAppStore()
  const theme = useTheme()

  const {
    type = 'info',
    message = '',
    actions = [],
  } = toast.state.open ? toast.state : {}
  const duration =
    (toast.state.open && toast.state.duration) || (actions.length > 0 ? 8000 : 4000)

  useKeyboardShortcuts({
    name: 'toast',
    node: document,
    handler: (shortcut, event) => {
      if (shortcut === 'Meta+KeyZ') {
        const undoAction = actions.find((a) => a.title === 'Undo')
        if (undoAction) {
          handleToastAction(undoAction.onClick)()
          event.preventDefault()
        }
      }
    },
    dep: [actions],
  })

  const handleToastAction = (handler?: () => void) => () => {
    handler?.()
    toast.hide()
  }

  const ToastButton = type === 'error' ? DangerButton : Button

  const renderActionButton = (action: ToastAction) => {
    const ActionButton = action.type === 'destructive' ? DangerButton : ToastButton

    return (
      <ActionButton
        variant="text"
        size="small"
        onClick={handleToastAction(action.onClick)}
      >
        {action.title}
      </ActionButton>
    )
  }

  const renderActions = () => {
    if (type === 'special') return null
    if (actions.length > 0) {
      return actions.map(renderActionButton)
    }
    return null
  }

  // reversing the theme for `info` and `loading` variants
  const themeKey =
    type === 'info' || type === 'loading'
      ? theme.match({ light: 'dark', dark: 'light' } as const)
      : theme.themeKey

  const themeClassName = themeClassNames[themeKey]

  return (
    <Snackbar
      className={cx(styles.root)}
      open={toast.state.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={type === 'loading' ? null : duration}
      transitionDuration={0}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') {
          toast.hide()
        }
      }}
    >
      <ThemeProvider themeKey={themeKey}>
        <SnackbarContent
          className={cx(
            themeClassName,
            styles.rootContent({ isError: type === 'error' }),
          )}
          type={type}
          message={
            <div className={styles.toastContent}>
              {type === 'loading' ? (
                <>
                  <LoadingIndicator
                    className={styles.toastLoading}
                    size={14}
                    variant="circular"
                    color="primary"
                  />
                  <div>{message}</div>
                </>
              ) : type === 'special' ? (
                <div>{message}</div>
              ) : (
                <Typography
                  variant="body"
                  color={type === 'error' ? 'error' : 'inherit'}
                  fontWeight={type === 'error' ? 'medium' : 'regular'}
                  style={{ fontSize: 'inherit ' }}
                >
                  {message}
                </Typography>
              )}
            </div>
          }
          action={renderActions()}
        />
      </ThemeProvider>
    </Snackbar>
  )
}

export default observer(Toast)
