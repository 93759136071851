import { makeAutoObservable, runInAction } from 'mobx'

import { insertAtIndex, removeAtIndex } from '@src/lib'
import { stripSkinVariation } from '@src/lib/emoji'
import { logError } from '@src/lib/log'
import type Service from '@src/service'
import type { ActivityModel, MessageMediaModel } from '@src/service/model'
import { Comment } from '@src/service/model'
import { ActivityReaction } from '@src/service/model/reactions'
import type {
  CommentDeleteMessage,
  CommentUpdateMessage,
} from '@src/service/transport/websocket'

export default class CommentStore {
  constructor(private service: Service) {
    makeAutoObservable(this, {})
    this.handleWebsocket()
  }

  private async addComment(comment: Comment) {
    runInAction(() => {
      comment.activity.comments.push(comment)
    })
    const conversation = this.service.conversation.collection.get(
      comment.activity.conversationId,
    )
    const phoneNumberId = conversation?.phoneNumberId ?? ''
    await Promise.all(comment.media.map((m) => this.service.media.upload(m)))

    runInAction(() => {
      this.service.activity.collection.put(comment.activity)
    })

    await this.service.transport.communication.comments.post(comment.toJSON())

    this.service.analytics.inbox.internalThreadMessageSent(phoneNumberId)
  }

  deleteComment(comment: Comment) {
    const commentIndex = comment.activity.comments.findIndex((c) => c === comment)
    comment.activity.comments = removeAtIndex(comment.activity.comments, commentIndex)
    this.service.activity.collection.put(comment.activity)
    return this.service.transport.communication.comments.delete(comment.id)
  }

  handleCommentUpdate(msg: CommentUpdateMessage) {
    const activity = this.service.activity.collection.get(msg.comment.activityId ?? null)
    if (!activity) return
    const comment = activity.comments.find((comment) => comment.id == msg.comment.id)
    if (comment) {
      comment.deserialize(msg.comment)
    } else {
      activity.comments.push(new Comment(activity, msg.comment))
    }
  }

  handleCommentDelete(msg: CommentDeleteMessage) {
    const activity = this.service.activity.collection.get(msg.comment.activityId ?? null)
    if (!activity) return
    const comment = activity.comments.find((comment) => comment.id == msg.comment.id)
    if (comment) this.deleteComment(comment)
  }

  sendComment(activity: ActivityModel, body: string, media: MessageMediaModel[]) {
    if (!this.service.user.current) {
      if (this.service.transport.online) {
        logError(new Error('Current user is null'))
        return Promise.reject(`An error occurred`)
      } else {
        return Promise.reject(`Can't perform this action while offline`)
      }
    }

    return this.addComment(
      new Comment(activity, {
        body,
        media,
        userId: this.service.user.current.id,
      }),
    )
  }

  private async addReaction(comment: Comment, reaction: ActivityReaction) {
    comment.reactions.push(reaction)
    this.service.activity.collection.put(reaction.activity)
    try {
      return await this.service.transport.communication.reactions.post(reaction.toJSON())
    } catch (error) {
      // Revert optimistic update
      const reactionToRemoveLocallyIndex = comment.reactions.findIndex(
        (r) => r === reaction,
      )
      runInAction(() => {
        comment.reactions = removeAtIndex(comment.reactions, reactionToRemoveLocallyIndex)
      })
      throw error
    }
  }

  private async deleteReaction(comment: Comment, reaction: ActivityReaction) {
    const reactionToRemoveLocallyIndex = comment.reactions.findIndex(
      (r) => r === reaction,
    )
    comment.reactions = removeAtIndex(comment.reactions, reactionToRemoveLocallyIndex)
    this.service.activity.collection.put(reaction.activity)
    try {
      return await this.service.transport.communication.reactions.delete(reaction.id)
    } catch (error) {
      // Revert optimistic update
      runInAction(() => {
        comment.reactions = insertAtIndex(
          comment.reactions,
          reaction,
          reactionToRemoveLocallyIndex,
        )
      })
      throw error
    }
  }

  async toggleReaction(comment: Comment, emoji: string) {
    const body = this.service.emoji.getEmojiWithSkinTone(emoji)
    const reaction = comment.reactions.find(
      (r) =>
        r.body &&
        stripSkinVariation(r.body) === stripSkinVariation(body) &&
        r.userId === this.service.user.current?.id,
    )

    if (reaction) {
      return this.deleteReaction(comment, reaction)
    } else {
      return this.addReaction(
        comment,
        new ActivityReaction(comment, {
          body,
          userId: this.service.user.current?.id,
        }),
      )
    }
  }

  private handleWebsocket() {
    this.service.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'comment-update':
          return this.handleCommentUpdate(msg)
        case 'comment-delete':
          return this.handleCommentDelete(msg)
      }
    })
  }
}
