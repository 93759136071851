import { when } from 'mobx'

import type AppStore from '@src/app/AppStore'
import { toE164 } from '@src/lib/phone-number'
import { ActivityModel } from '@src/service/conversation-store'

import ProtocolHandler from './ProtocolHandler'

export const PROTOCOL = 'openphone'

/**
 * The protocol handler for OpenPhone.
 *
 * The `openphone:` protocol is a mechanism to encode actions in URL format,
 * allowing other components within OpenPhone (e.g. backend) to trigger certain
 * actions within the app.
 *
 * Available actions are:
 *  - `openphone://login?access_token=<token>`: login via google
 *  - `openphone://dial?number=<phone number>`: open the dialer with a phone number
 *  - `openphone://message?number=<phone number>`: open a new conversation in the selected inbox
 *  - `openphone://<path>`: navigate to a path within the app
 */
export default class OpenPhoneProtocolHandler extends ProtocolHandler {
  readonly protocol: string = PROTOCOL
  readonly web: boolean = false
  readonly desktop: boolean = true

  /**
   * A map of path-matching regexes to their respective handler methods.
   *
   * The regex is only for the pathname, not the query string.
   */
  protected readonly handlers: ReadonlyMap<
    RegExp,
    (url: URL, match: RegExpMatchArray) => void
  >

  constructor(app: AppStore) {
    super(app)
    // FIXME: replace with URL Pattern API
    // see https://linear.app/openphone/issue/ENG-3529
    this.handlers = new Map([
      [/^\/\/login\/?$/, this.handleLogin],
      [/^\/\/dial\/?$/, this.handleDial],
      [/^\/\/message\/?$/, this.handleMessage],
      [/^\/\/alert\/?$/, this.handleAlerts],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/alert\/([^\/]+)\/?$/, this.handleAlert],
      [/^\/\/contacts\/?$/, this.handleContacts],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/contact\/([^\/]+)\/?$/, this.handleContact],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/contact\/([^\/]+)\/note\/([^\/]+)\/?$/, this.handleContactNote],
      [/^\/\/conversation\/?$/, this.handleInbox],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/conversation\/([^\/]+)\/?$/, this.handleConversation],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/conversation\/([^\/]+)\/activity\/([^\/]+)\/?$/, this.handleConversation],
      [
        // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
        /^\/\/conversation\/([^\/]+)\/activity\/([^\/]+)\/comment\/?$/,
        this.handleConversation,
      ],
      [
        // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
        /^\/\/conversation\/([^\/]+)\/activity\/([^\/]+)\/comment\/([^\/]+)\/?$/,
        this.handleConversation,
      ],
      [/^\/\/settings\/?$/, this.handleSettings],
      [/^\/\/settings\/billing\/?$/, this.handleSettingsBilling],
      [/^\/\/settings\/billing\/plans\/?$/, this.handleSettingsBillingPlans],
      [/^\/\/settings\/blocklist\/?$/, this.handleSettingsBlocklist],
      [/^\/\/settings\/company\/?$/, this.handleSettingsCompany],
      [/^\/\/settings\/contacts\/?$/, this.handleSettingsContacts],
      [/^\/\/settings\/groups\/?$/, this.handleSettingsGroups],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/settings\/integrations(?:\/([^\/]+))?\/?$/, this.handleSettingsIntegrations],
      [/^\/\/settings\/members\/?$/, this.handleSettingsMembers],
      [/^\/\/settings\/notifications\/?$/, this.handleSettingsNotifications],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/settings\/phone-numbers(?:\/([^\/]+))?\/?$/, this.handleSettingsPhoneNumber],
      [/^\/\/settings\/preferences\/?$/, this.handleSettingsPreferences],
      [/^\/\/settings\/profile\/?$/, this.handleSettingsProfile],
      [/^\/\/settings\/referrals\/?$/, this.handleSettingsReferrals],
      [/^\/\/settings\/trust\/?$/, this.handleSettingsTrust],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/settings\/webhooks(?:\/([^\/]+))?\/?$/, this.handleSettingsWebhooks],
      // eslint-disable-next-line no-useless-escape -- FIXME: Fix this ESLint violation!
      [/^\/\/callback\/([^\/]+)\/?$/, this.handleCallbackRedirect],
    ])
  }

  handle(url: URL): void {
    const path = url.pathname

    for (const [regex, handler] of this.handlers.entries()) {
      const match = path.match(regex)

      if (match) {
        return handler(url, match)
      }
    }
  }

  protected handleLogin = (url: URL) => {
    const loginStore = this.app.login
    // FIXME: make LoginUiStore a permanent instance in AppStore
    if (!loginStore) return
    const token = url.searchParams.get('access_token')
    if (!token) return
    loginStore.loading = 'logging_in'
    this.app.service.auth
      .googleSignin(token, loginStore.inviteCode)
      .catch(this.app.toast.showError)
      .finally(loginStore.stopLoading)
  }

  protected handleDial = (url: URL) => {
    const number = url.searchParams.get('number')
    if (!number) return
    const phoneNumber = this.normalizePhoneNumber(number)
    this.app.command.present({ name: 'dialer', phoneNumber })
  }

  protected handleMessage = (url: URL) => {
    const numberParam = url.searchParams.get('number')
    if (!numberParam) return
    const numbers = (numberParam ?? '').split(',').map(toE164)
    const message = url.searchParams.get('text') ?? undefined
    when(
      () => !!this.app.inboxes.selected,
      () => {
        this.app.inboxes.selected?.newConversation(numbers.join(','), true, message)
      },
    )
  }

  protected handleAlerts = () => {
    this.app.alerts.show()
  }

  protected handleAlert = (url: URL, match: RegExpMatchArray) => {
    const id = match[1]
    this.app.alerts.show(id)
  }

  protected handleContacts = (url: URL, match: RegExpMatchArray) => {
    this.app.contacts.show(url.searchParams)
  }

  protected handleContact = (url: URL, match: RegExpMatchArray) => {
    const id = match[1]
    this.app.contacts.showContact(id)
  }

  protected handleContactNote = (url: URL, match: RegExpMatchArray) => {
    const id = match[1]
    this.app.contacts.showContact(id)
  }

  protected handleInbox = (url: URL) => {
    const phoneNumberId = url.searchParams.get('phoneNumberId')
    const directNumberId = url.searchParams.get('directNumberId')

    if (phoneNumberId || directNumberId) {
      const inbox = this.app.inboxes.all.find(
        (i) => i.id === phoneNumberId || i.id === directNumberId,
      )
      this.app.inboxes.setSelected(inbox)
    }
  }

  protected handleConversation = async (url: URL, match: RegExpMatchArray) => {
    const conversationId = match[1]
    const anchorActivityId = match[2]

    if (!ActivityModel.validateActivityId(anchorActivityId)) {
      throw new Error(
        `Attempted to open a conversation with an invalid Activity ID. ${anchorActivityId}`,
      )
    }

    this.app.inboxes
      .openConversationById(conversationId, anchorActivityId)
      .catch(this.app.toast.showError)
  }

  protected handleSettings = () => {
    this.app.history.push('/settings')
  }

  protected handleSettingsBilling = () => {
    this.app.history.push('/settings/billing')
  }

  protected handleSettingsBillingPlans = () => {
    this.app.history.push('/settings/billing/plans')
  }

  protected handleSettingsBlocklist = () => {
    this.app.history.push('/settings/blocklist')
  }

  protected handleSettingsCompany = () => {
    this.app.history.push('/settings/company')
  }

  protected handleSettingsContacts = () => {
    this.app.history.push('/settings/contacts')
  }

  protected handleSettingsGroups = () => {
    this.app.history.push('/settings/groups')
  }

  protected handleSettingsIntegrations = (url: URL, match: RegExpMatchArray) => {
    const integration = match[1]
    this.app.history.router.navigate(
      {
        pathname: integration
          ? `/settings/integrations/${integration}`
          : '/settings/integrations',
        search: url.search,
      },
      { replace: true },
    )
  }

  protected handleSettingsMembers = () => {
    this.app.history.push('/settings/members')
  }

  protected handleSettingsNotifications = () => {
    this.app.history.push('/settings/notifications')
  }

  protected handleSettingsPhoneNumber = (url: URL, match: RegExpMatchArray) => {
    const phoneNumberId = match[1]
    this.app.history.router.navigate(
      {
        pathname: phoneNumberId
          ? `/settings/phone-numbers/${phoneNumberId}`
          : '/settings/phone-numbers',
        search: url.search,
      },
      { replace: true },
    )
  }

  protected handleSettingsPreferences = () => {
    this.app.history.push('/settings/preferences')
  }

  protected handleSettingsProfile = () => {
    this.app.history.push('/settings/profile')
  }

  protected handleSettingsReferrals = () => {
    this.app.history.push('/settings/referrals')
  }

  protected handleSettingsTrust = () => {
    this.app.history.push('/settings/trust')
  }

  protected handleSettingsWebhooks = (url: URL, match: RegExpMatchArray) => {
    const webhookId = match[1]
    this.app.history.push(
      webhookId ? `/settings/webhooks/${webhookId}` : '/settings/webhooks',
    )
  }

  protected handleCallbackRedirect = (url: URL, match: RegExpMatchArray) => {
    const provider = match[1]
    this.app.history.push(`/callback/${provider}`)
  }
}
