import { makeAutoObservable, toJS } from 'mobx'

import { getInitials } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import uuid from '@src/lib/uuid'
import type WorkspaceStore from '@src/service/WorkspaceStore'

import type { Identity, IdentityPhone, Model } from './base'
import type { Member } from './member'

export interface GroupMembership {
  groupId: string
  userId: string
  role: string
}

export interface CodableGroup {
  id: string
  name: string
  type: string | null
  symbol: string | null
  description: string | null
  orgId: string | null

  createdBy: string | null
  deletedAt: string | null
  createdAt: string | null
  updatedAt: string | null
  members: GroupMembership[]
}

export class GroupModel implements Model, Identity {
  id: string = `GR${uuid()}`.replace(/-/g, '')
  name = ''
  type: string | null = null
  symbol = ''
  description = ''
  orgId: string | null = null

  createdBy: string | null = null
  deletedAt: string | null = null
  createdAt: string | null = null
  updatedAt: string | null = null
  members: GroupMembership[] = []

  constructor(
    private workspaceStore: WorkspaceStore,
    attrs: Partial<CodableGroup>,
  ) {
    this.deserialize(attrs)

    makeAutoObservable(this, {})
  }

  get membersIdentities(): Member[] {
    return this.members
      .map((member) => this.workspaceStore.getMember(member.userId))
      .filter(isNonNull)
  }

  set membersIdentities(members: Member[]) {
    this.members = members.map((member) => ({
      userId: member.id,
      groupId: this.id,
      role: member.role,
    }))
  }

  get shortName() {
    return this.name
  }

  get initials() {
    return getInitials(this.name)
  }

  get pictureUrl() {
    return ''
  }

  get isAnonymous() {
    return false
  }

  get phones(): IdentityPhone[] {
    return this.membersIdentities.reduce(
      (acc, member) => [...acc, ...member.phoneNumbers],
      [] as IdentityPhone[],
    )
  }

  get emailAddresses() {
    return this.membersIdentities.map((member) => member.email)
  }

  save() {
    if (!this.name) return null

    if (!this.id) {
      this.id = `GR${uuid()}`.replace(/-/g, '')

      return this.workspaceStore.createGroup(this.serialize())
    }

    return this.workspaceStore.updateGroup(this.serialize())
  }

  delete() {
    if (this.id) {
      this.deletedAt = Date.now().toString()
      return this.workspaceStore.deleteGroup(this.id)
    }
  }

  deserialize(json: Partial<CodableGroup>) {
    Object.assign(this, json)

    return this
  }

  serialize(): CodableGroup {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      symbol: this.symbol,
      description: this.description,
      orgId: this.orgId,
      createdBy: this.createdBy,
      deletedAt: this.deletedAt,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      members: toJS(this.members),
    }
  }
}
