import * as LDClient from 'launchdarkly-js-client-sdk'
import { action, computed, makeObservable, observable } from 'mobx'

import config, { isWeb } from '@src/config'
import type Service from '@src/service'
import type { OrganizationModel, SubscriptionModel, UserModel } from '@src/service/model'
import makePersistable from '@src/service/storage/makePersistable'

import type { FlagKey, FlagValue, Flags } from './FlagsTypes'
import defaultFlags from './defaultFlags'
import launchDarklyAdapter from './launchDarklyAdapter'

const staticAnonymousUser: LDClient.LDSingleKindContext = {
  kind: 'user',
  key: '__anonymous__',
  anonymous: true,
}

type Custom = NonNullable<
  Record<string, string | boolean | number | (string | boolean | number)[]>
>

export interface IFlagsService {
  flags: Flags
  getFlag<Key extends FlagKey>(key: Key, defaultValue?: FlagValue<Key>): FlagValue<Key>
  identify(
    user: UserModel,
    organization: OrganizationModel,
    subscription: SubscriptionModel,
  ): Promise<void>
  waitUntilReady(): Promise<void>
  tearDown(): Promise<void>
}

export default class FlagsService implements IFlagsService {
  private ldClient: LDClient.LDClient
  private ldFlags: LDClient.LDFlagSet = {}

  constructor(private readonly root: Service) {
    makeObservable<this, 'ldFlags' | 'syncFlags'>(this, {
      ldFlags: observable,
      flags: computed,
      syncFlags: action.bound,
    })

    makePersistable(this, 'FlagsService', {
      ldFlags: this.root.storage.async(),
    })

    this.ldClient = LDClient.initialize(
      config.LAUNCH_DARKLY_CLIENT_SIDE_ID,
      staticAnonymousUser,
      {},
    )

    this.ldClient.on('change', this.syncFlags)
  }

  get flags(): Flags {
    return launchDarklyAdapter(this.ldFlags)
  }

  getFlag<Key extends FlagKey>(key: Key, defaultValue?: FlagValue<Key>): FlagValue<Key> {
    const isDefinedFlag = (flag: Flags[Key]): flag is FlagValue<Key> => flag !== undefined
    const flag = this.flags[key]

    if (isDefinedFlag(flag)) {
      return flag
    }

    return defaultValue ?? defaultFlags[key]
  }

  async tearDown() {
    await this.ldClient.close()
  }

  async waitUntilReady() {
    await this.ldClient.waitUntilReady()
    this.syncFlags()
  }

  async identify(
    user: UserModel,
    organization: OrganizationModel,
    subscription: SubscriptionModel,
  ) {
    const custom: Custom = {
      appVersion: config.VERSION,
      organizationId: organization.id,
      organizationCreatedAt: new Date(organization.createdAt ?? 0).toISOString(),
    }

    if (subscription.type) {
      custom.subscriptionType = subscription.type
    }

    await this.ldClient.identify({
      kind: 'multi',
      user: {
        key: user.id,
        avatar: user.pictureUrl ?? '',
        email: user.email ?? '',
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        ...custom,
      },
      device: {
        key: user.id + '_' + (isWeb ? 'web' : 'desktop'),
        platform: isWeb ? 'web' : 'desktop',
      },
    })
  }

  private syncFlags() {
    this.ldFlags = this.ldClient.allFlags()
  }
}
