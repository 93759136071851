import { makeAutoObservable, toJS } from 'mobx'

import { getInitials, getSingleFirstAndLastName, parseDate } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import isTruthy from '@src/lib/isTruthy'
import type { RoleName } from '@src/service/transport/account'

import type Service from '..'

import { compareByName } from '.'
import type {
  EntityPhoneNumberModel,
  Identity,
  IdentityPhone,
  Model,
  PhoneNumber,
  PresenceModel,
} from '.'

export interface MemberPhoneNumber {
  id: string
  number: string
  name: string
  symbol: string
}

export interface MemberDirectNumber {
  id: string
  number: string
}

export interface SerializedMember {
  id: string
  firstName: string
  lastName: string
  email: string
  pictureUrl: string | null
  status: 'invited' | 'active' | 'suspended' | 'archived'
  role: RoleName
  phoneNumbers: { number: string }[]
  createdAt: number | null
  updatedAt: number | null
  _directNumber: string | null
  _directNumberId: string | null
  _directNumbers: MemberDirectNumber[]
}

export class Member implements Identity, Model {
  id = ''
  firstName = ''
  lastName = ''
  email = ''
  pictureUrl: string | null = null
  pictureSymbol = undefined
  status: 'invited' | 'active' | 'suspended' | 'archived' = 'active'
  role: RoleName = 'member'
  phoneNumbers: MemberPhoneNumber[] = []
  createdAt: number | null = null
  updatedAt: number | null = null

  private _directNumber: string | null = null
  private _directNumberId: string | null = null
  private _directNumbers: MemberDirectNumber[] = []

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get name(): string {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].filter(isTruthy).join(' ')
    }
    return this.email.split('@')[0]
  }

  get nameWithStatus(): string {
    return [this.name, this.statusSymbol].filter(isNonNull).join(' ')
  }

  get nameWithSymbol(): string {
    return [this.name, this.symbol].filter(isNonNull).join(' ')
  }

  get shortName(): string {
    return this.firstName
  }

  get initials(): string {
    if (this.firstName || this.lastName) {
      const singleFirstNameAndLastName = getSingleFirstAndLastName(
        this.firstName,
        this.lastName,
      )
      return getInitials(singleFirstNameAndLastName)
    }
    return ''
  }

  get presence(): PresenceModel | null {
    return this.root.member.presence.get(this.id)
  }

  get isOffHours(): boolean {
    return this.presence?.schedule?.isOffHours ?? false
  }

  get sharedPhoneNumbers(): PhoneNumber[] {
    return this.root.phoneNumber.collection.list.filter((pn) => pn.isSharedWith(this))
  }

  get phones(): IdentityPhone[] {
    return this.sharedPhoneNumbers.map((p) => ({
      id: p.id,
      name: p.name,
      symbol: p.symbol,
      number: p.number,
      isOffHours: p.isOffHours,
      isShared: p.isShared,
    }))
  }

  get emailAddresses(): string[] {
    return [this.email]
  }

  get isOwner(): boolean {
    return ['owner'].includes(this.role)
  }

  get isAdmin(): boolean {
    return ['owner', 'admin'].includes(this.role)
  }

  get symbol(): string | null {
    return this.presence?.symbol ?? null
  }

  get statusSymbol(): string | null {
    if (
      this.presence?.onCall &&
      this.root.capabilities.features.showOnCallStatusEnabled
    ) {
      return '📞'
    }
    return this.symbol
  }

  get statusText() {
    if (
      this.presence?.onCall &&
      this.root.capabilities.features.showOnCallStatusEnabled
    ) {
      return 'On a call...'
    }
    return this.presence?.text
  }

  get isAnonymous() {
    return false
  }

  get directNumber(): MemberDirectNumber | undefined {
    // TODO: use orgId to choose correct number instead of indexes
    // Note: currently we have only a single directNumber per user
    // due to each user associated with a single organization
    return this._directNumbers[0]
  }

  canAdminPhoneNumber = (phoneNumber: PhoneNumber | EntityPhoneNumberModel): boolean => {
    return (
      this.isAdmin ||
      Boolean(
        phoneNumber?.users?.find(
          (u) => u.id === this.id && (u.role === 'owner' || u.role === 'admin'),
        ),
      )
    )
  }

  canChangePhoneNumberOwner(phoneNumber: PhoneNumber | EntityPhoneNumberModel): boolean {
    return (
      this.isAdmin ||
      Boolean(phoneNumber?.users?.find((u) => u.id === this.id && u.role === 'owner'))
    )
  }

  canManage(role: RoleName) {
    const permissionsMap: { [key in RoleName]: RoleName[] } = {
      owner: ['owner', 'admin', 'member'],
      admin: ['admin', 'member'],
      member: ['member'],
    }

    const safeRole: RoleName = this.role in permissionsMap ? this.role : 'member'

    return permissionsMap[safeRole].includes(role)
  }

  canVerifyIdentity() {
    return this.isOwner
  }

  setRole = (role: RoleName) => {
    this.role = role
    return this.root.member.setRole(this)
  }

  delete = () => {
    if (this.status === 'invited') {
      return this.root.member.uninvite(this.id)
    } else {
      return this.root.member.delete(this)
    }
  }

  deserialize = ({ directNumber, directNumberId, directNumbers, ...json }: any) => {
    Object.assign(this, json)
    if (typeof directNumber !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      this._directNumber = directNumber
    }
    if (typeof directNumberId !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      this._directNumberId = directNumberId
    }
    if (typeof directNumbers !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      this._directNumbers = directNumbers
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  serialize = (): SerializedMember => {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      pictureUrl: this.pictureUrl,
      status: this.status,
      role: this.role,
      phoneNumbers: toJS(this.phoneNumbers),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      _directNumber: this._directNumber,
      _directNumberId: this._directNumberId,
      _directNumbers: toJS(this._directNumbers),
    }
  }
}

export const isMember = (a: unknown): a is Member => {
  return a instanceof Member
}

export const compareMembersByPresence = (a: Member, b: Member): number => {
  const aWeight = a.presence?.sortWeight ?? 0
  const bWeight = b.presence?.sortWeight ?? 0

  const diff = aWeight - bWeight
  return diff === 0 ? compareByName(a, b) : diff
}
