import type { Debugger } from 'debug'
import Debug from 'debug'
import { makeAutoObservable, reaction } from 'mobx'

import { unique } from '@src/lib'
import { DisposeBag } from '@src/lib/dispose'
import isNonNull from '@src/lib/isNonNull'
import type Service from '@src/service'
import type { Member, PhoneNumber } from '@src/service/model'
import {
  type ParticipantTarget,
  type UpdateParticipantsParams,
} from '@src/service/transport/voice'

import type { RoomParticipant } from '.'
import type ActiveCall from './ActiveCall'
import ActiveCallParticipant from './ActiveCallParticipant'
import convertSelectionToVoiceTarget from './convertSelectionToVoiceTarget'

export default class ActiveCallParticipants {
  protected debug: Debugger
  protected disposeBag = new DisposeBag()

  toId: string | null = null
  fromId: string | null = null
  currentId: string | null = null
  transferrerId: string | null = null

  constructor(
    protected readonly root: Service,
    protected readonly call: ActiveCall,
  ) {
    this.debug = Debug(`op:service:voice:ActiveCallParticipants:@${this.call.id}`)

    makeAutoObservable(this, {}, { autoBind: true })

    this.disposeBag.add(
      reaction(
        () => this.call.data.room.participants,
        (participants) => {
          this.debug('room participants changed (%O)', participants)
          for (const participant of participants) {
            if (
              participant.userId === this.root.user.current?.id &&
              // If the current user is being added back to the call after being removed its userId will
              // match more than one participant, so we need to check that we are only matching participants
              // that have not been removed
              !participant.removedAt
            ) {
              this.currentId = participant.id
            }
            if (participant.id === this.call.data.toParticipantId) {
              this.toId = participant.id
            }
            if (participant.id === this.call.data.fromParticipantId) {
              this.fromId = participant.id
            }
            if (participant.id === this.call.data.transferrerParticipantId) {
              this.transferrerId = participant.id
            }
          }
        },
        { name: 'ActiveCallParticipants.RoomParticipantsChanged', fireImmediately: true },
      ),
    )
  }

  private get participantsMap(): Map<string, RoomParticipant> {
    return new Map(this.call.data.room.participants.map((p) => [p.id, p]))
  }

  /**
   * Represents a map of all the unique users that have participated to the call.
   *
   * This also accounts for users that have been added and removed from the call multiple
   * times. To identify users we use the `identity` property. If not available, we fallback
   * to the `identifier` property.
   */
  private get identitiesMap(): Map<string, RoomParticipant> {
    return new Map(
      this.call.data.room.participants.map((p) => {
        const participant = this.convertToParticipant(p)
        return [participant.identity?.id ?? p.identifier, p]
      }),
    )
  }

  get identitiesCount(): number {
    return this.identitiesMap.size
  }

  get list(): ActiveCallParticipant[] {
    const presets = unique([this.current, this.to, this.from])
      .filter(isNonNull)
      .filter((p) => !p.roomParticipant.removedAt)

    const presetIds = new Set(presets.map((p) => p.id))

    const active = this.call.data.room.participants
      .filter((p) => !p.removedAt && !presetIds.has(p.id))
      .map(this.convertToParticipant)

    // The trasferrer needs to be filtered out since it doesn't belong to this room
    // and we won't get websocket updates for it from the backend
    return [...presets, ...active].filter((p) => p.id !== this.transferrerId)
  }

  get identifiers(): string[] {
    return this.list.map((p) => p.identifier)
  }

  get current(): ActiveCallParticipant | null {
    const p = this.participantsMap.get(this.currentId as string)
    return p ? this.convertToParticipant(p) : null
  }

  get from(): ActiveCallParticipant | null {
    if (this.fromId === this.currentId) {
      return this.current
    }

    const p = this.participantsMap.get(this.fromId as string)
    return p ? this.convertToParticipant(p) : null
  }

  get to(): ActiveCallParticipant | null {
    if (this.toId === this.currentId) {
      return this.current
    }

    const p = this.participantsMap.get(this.toId as string)
    return p ? this.convertToParticipant(p) : null
  }

  get transferrer(): ActiveCallParticipant | null {
    if (this.transferrerId === this.currentId) {
      return this.current
    }

    const p = this.participantsMap.get(this.transferrerId as string)
    return p ? this.convertToParticipant(p) : null
  }

  get others(): ActiveCallParticipant[] {
    return this.list.filter((p) => p !== this.current)
  }

  get externals(): ActiveCallParticipant[] {
    return this.list.filter((p) => p.isExternal)
  }

  get listeners(): ActiveCallParticipant[] {
    return this.list.filter((p) => p.isListener)
  }

  get whisperers(): ActiveCallParticipant[] {
    return this.list.filter((p) => p.coaching === this.current)
  }

  get speakers(): ActiveCallParticipant[] {
    return this.list.filter((p) => !p.isListener)
  }

  get fromNumber(): string | null {
    return this.from?.roomParticipant.identifier ?? null
  }

  get toPhoneNumber(): PhoneNumber | null {
    const toNumber = this.to?.roomParticipant.identifier

    return toNumber ? this.root.phoneNumber.byNumber[toNumber] : null
  }

  get toMember(): Member | null {
    return this.call.data.toUserId
      ? this.root.member.collection.get(this.call.data.toUserId)
      : null
  }

  get toNumber(): string | null {
    return this.to?.roomParticipant.identifier ?? null
  }

  add(
    participants: ActiveCallParticipant[],
    message: string,
    fromPhoneNumberId?: string,
  ) {
    const targets: ParticipantTarget[] = []

    for (const participant of participants) {
      if (participant.selection) {
        targets.push({
          ...convertSelectionToVoiceTarget(participant.selection),
          message,
          participantId: participant.id,
          isListener: participant.isListener,
        })
      }
    }

    this.debug('adding participants (participants: %O)', participants)

    const oldCount = this.call.participants.list.length

    this.call.data.room.participants.push(...participants.map((p) => p.roomParticipant))

    const newCount = this.call.participants.list.length

    this.root.analytics.voice.callParticipantsAdded(
      participants.length,
      oldCount,
      newCount,
      participants.map((p) => (p.isListener ? 'add-listener' : 'add')),
      message.length,
      participants
        .map((p) => {
          if (p.selection) {
            if (p.selection.type === 'add-number') {
              return 'number'
            }
            return p.selection.type
          }
          return null
        })
        .filter(isNonNull),
    )

    return this.root.voice
      .addParticipants(this.call.data.room.id, {
        to: targets,
        from: { participantId: this.current?.id ?? '', phoneNumberId: fromPhoneNumberId },
      })
      .catch((error) => {
        const participantsIds = participants.map((p) => p.id)
        this.call.data.room.participants = this.call.data.room.participants.filter(
          (participant) => !participantsIds.includes(participant.id),
        )
        throw error
      })
  }

  retryAdd(participantId: string): Promise<void> {
    this.call.updateRoomParticipant(participantId, {
      status: 'ringing',
    })
    return this.root.voice
      .retryAddingParticipant(this.call.data.room.id, participantId)
      .catch((error) => {
        this.call.updateRoomParticipant(participantId, {
          status: 'failed',
        })
        throw error
      })
  }

  update(participantId: string, params: UpdateParticipantsParams): Promise<void> {
    this.call.updateRoomParticipant(participantId, params)
    return this.root.voice.updateParticipant(
      this.call.data.room.id,
      participantId,
      params,
    )
  }

  remove(participantId: string): Promise<void> {
    this.call.updateRoomParticipant(participantId, {
      removedAt: new Date().toISOString(),
    })
    return this.root.voice
      .removeParticipant(this.call.data.room.id, participantId)
      .catch((error) => {
        this.call.updateRoomParticipant(participantId, {
          removedAt: null,
        })
        throw error
      })
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  private convertToParticipant(roomParticipant: RoomParticipant): ActiveCallParticipant {
    return new ActiveCallParticipant(this.root, this.call, roomParticipant)
  }
}
