import { useEffect, useRef, useState } from 'react'

import { useAppStore } from '@src/app/context'
import Button from '@src/component/ButtonV2'
import useAsyncCopyToClipboard from '@src/lib/hooks/useAsyncCopyToClipboard'
import Tooltip from '@ui/Tooltip'
import Typography from '@ui/Typography'
import { VStack } from '@ui/layout/Stack'

import * as styles from './GenericErrorView.css'

export interface GenericErrorViewProps {
  error: unknown
  reset: () => void
  title?: string
  description?: string
  showResetAllDataAction?: boolean
}

export default function GenericErrorView({
  error,
  reset,
  title = 'Oops...',
  description = `Seems like something broke. We got a report of the problem. Reloading the app should restore your previous session. If the problem persists, please contact support.`,
}: GenericErrorViewProps) {
  const timeoutRef = useRef<number>()
  const [copyLabel, setCopyLabel] = useState('Click to copy')
  const app = useAppStore()
  const copyToClipboard = useAsyncCopyToClipboard()

  const message =
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
      ? error.message
      : String(error)

  const handleCopy = () => {
    const errorData =
      // Attempt to get the error stack from the error object
      typeof error === 'object' &&
      error !== null &&
      'stack' in error &&
      typeof error.stack === 'string'
        ? error.stack
        : // If we're unable to get the error stack from the error object, we'll try with the error message
          message

    copyToClipboard({
      copyString: errorData,
      successMessage: 'Copied to clipboard!',
    })
    clearTimeout(timeoutRef.current)
    setCopyLabel('Copied!')
    timeoutRef.current = window.setTimeout(() => {
      setCopyLabel('Click to copy')
    }, 1000)
  }

  useEffect(() => {
    app.update.checkForUpdate()
  }, [app.update])

  return (
    <VStack>
      <Typography variant="largeTitle" style={{ textAlign: 'center', marginBottom: 30 }}>
        {title}
      </Typography>
      <Typography
        variant="body"
        color="textSecondary"
        style={{ maxWidth: 350, textAlign: 'center' }}
      >
        {description}
      </Typography>

      <Tooltip title={copyLabel} placement="top">
        <div className={styles.error} onClick={handleCopy}>
          {message}
        </div>
      </Tooltip>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 30,
          gap: 10,
        }}
      >
        <Button color="primary" variant="outlined" onClick={reset}>
          Reload
        </Button>
        <Button color="danger" variant="outlined" onClick={app.reset}>
          Reset all data and reload
        </Button>
      </div>
    </VStack>
  )
}
