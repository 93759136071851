import type { Debugger } from 'debug'
import Debug from 'debug'
import { action, computed, makeObservable } from 'mobx'

import { insertAtIndex, removeAtIndex, sortedIndex } from '@src/lib'
import shortId from '@src/lib/shortId'

export interface ImmutableCollectionOptions<T extends { [key: string]: any }> {
  /**
   * Specify the unique identifier of the elements.
   *
   * @default id
   */
  readonly idKey?: string

  /**
   * Set a filter function for this collection.
   *
   * If set, the collection will filter its elements.
   */
  filter?: ((item: T) => boolean) | null

  /**
   * Set a comparator function for this collection.
   *
   * If set, the collection will sort its elements.
   */
  compare?: ((a: T, b: T) => number) | null
}

export default class ImmutableCollection<T extends { [key: string]: any }> {
  protected elements: Map<string, T> = new Map()
  protected elementIds: string[] = []

  protected debug: Debugger
  protected idKey: string
  protected filter: ((item: T) => boolean) | null = null
  protected compare: ((a: T, b: T) => number) | null = null

  constructor(
    items: readonly T[] | undefined,
    { idKey = 'id', filter = null, compare = null }: ImmutableCollectionOptions<T> = {},
  ) {
    this.idKey = idKey
    this.filter = filter
    this.compare = compare
    this.debug = Debug(`op:collection:${shortId()}`)
    if (items) this._putBulk(items)

    makeObservable<this, '_insertId' | '_put' | '_putBulk'>(this, {
      keys: computed,
      list: computed,
      length: computed,
      _insertId: action,
      _put: action,
      _putBulk: action,
    })
  }

  get list(): T[] {
    return this.elementIds.map((id) => this.elements.get(id) as T)
  }

  get keys(): readonly string[] {
    return this.elementIds
  }

  get length(): number {
    return this.elements.size
  }

  indexOf(item: T | string | null): number {
    if (!item) return -1
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const id = typeof item === 'string' ? item : item.id
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    return this.elementIds.indexOf(id)
  }

  find<S extends T>(handler: (item: T) => item is S): S | undefined
  find(handler: (item: T) => boolean): T | undefined
  find(handler: (item: T) => boolean): T | undefined {
    return this.list.find(handler)
  }

  get(id: string): T | null {
    return this.elements.get(id) ?? null
  }

  has(id: string): boolean {
    return this.elements.has(id)
  }

  protected _put(obj: T) {
    if (this.filter && !this.filter(obj)) return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    this.elements.set(obj[this.idKey], obj)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    this._insertId(obj[this.idKey])
  }

  protected _putBulk(objs: readonly T[]) {
    const { compare, filter } = this

    if (filter) {
      objs = objs.filter((obj: T) => filter(obj))
    }

    const newIds = objs
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      .filter((obj) => !this.elements.has(obj[this.idKey]))
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      .map((obj) => obj[this.idKey])

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    this.elementIds.push(...newIds)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    objs.forEach((obj) => this.elements.set(obj[this.idKey], obj))

    if (compare) {
      this.elementIds.sort((a, b) => compare(this.get(a) as T, this.get(b) as T))
    }
  }

  protected _insertId(id: string) {
    const { compare } = this

    const oldIndex = this.elementIds.indexOf(id)

    if (compare) {
      if (oldIndex >= 0) this.elementIds = removeAtIndex(this.elementIds, oldIndex)
      const index = sortedIndex(this.elementIds, id, (a, b) =>
        compare(this.get(a) as T, this.get(b) as T),
      )
      this.elementIds = insertAtIndex(this.elementIds, id, index)
    } else {
      if (oldIndex < 0) this.elementIds.push(id)
    }
  }
}
