import type { BannerKey } from '@src/app/banner'

import useBannersContext from './useBannersContext'

export default function useBanner(key?: BannerKey) {
  const banners = useBannersContext()

  const isDismissed = key ? banners.config[key].isDismissed : false

  const dismiss = () => {
    if (!key) return
    banners.dismiss(key)
  }

  return { isDismissed, dismiss }
}
