import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import Collection from '@src/service/collections/Collection'
import type UserStore from '@src/service/user-store'

import type { CodableDirectNumber, CodableUserSettings, Member, Model } from '.'
import { DirectNumberModel, UserSettingsModel } from '.'

export interface UserAnalytics {
  referrer: string
  /**
   * Enrichment data from Clearbit
   *
   * @see https://dashboard.clearbit.com/docs#enrichment-api-person-api
   */
  enrichment?: {
    employment: {
      role: string
      subRole: string
    }
    emailProvider?: boolean
  }
  [key: string]: any
}

interface BaseCodableUser {
  id: string
  blocked: boolean
  email: string | null
  firstName: string | null
  lastName: string | null
  pictureUrl: string | null
  verifiedPhoneNumber: string | null
  analytics: UserAnalytics | null
  settings: CodableUserSettings
  directNumbers: CodableDirectNumber[]
}

export interface CodableUser extends BaseCodableUser {
  createdAt: number | null
  updatedAt: number | null
}

export interface EncodableUser extends BaseCodableUser {
  createdAt: number | string | null
  updatedAt: number | string | null
}

class UserModel implements Omit<CodableUser, 'directNumbers'>, Model {
  id = ''
  blocked = false
  email: string | null = null
  firstName: string | null = null
  lastName: string | null = null
  pictureUrl: string | null = null
  createdAt: number | null = null
  updatedAt: number | null = null
  verifiedPhoneNumber: string | null = null
  analytics: UserAnalytics | null = null
  settings: UserSettingsModel

  directNumbers = new Collection<DirectNumberModel>({ bindElements: true })

  constructor(
    private userStore: UserStore,
    attrs: EncodableUser,
  ) {
    this.settings = new UserSettingsModel(this.userStore, {})

    this.deserialize(attrs)

    makeAutoObservable(this, {})
  }

  get asMember(): Member | null {
    return this.userStore.getMember(this.id)
  }

  update = (attrs: Partial<CodableUser>) => {
    Object.assign(this, attrs)

    this.userStore.update(this.serialize())
  }

  deserialize = ({
    directNumbers,
    createdAt,
    updatedAt,
    settings,
    ...json
  }: EncodableUser) => {
    Object.assign(this, json)

    if (directNumbers) {
      this.directNumbers.clear()
      this.directNumbers.putBulk(directNumbers.map((dn) => new DirectNumberModel(dn)))
    }

    if (createdAt) {
      this.createdAt = parseDate(createdAt)
    }

    if (updatedAt) {
      this.updatedAt = parseDate(updatedAt)
    }

    this.settings.tearDown()
    this.settings = new UserSettingsModel(this.userStore, settings)

    return this
  }

  serialize = (): CodableUser => {
    return {
      id: this.id,
      blocked: this.blocked,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      pictureUrl: this.pictureUrl,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      verifiedPhoneNumber: this.verifiedPhoneNumber,
      analytics: toJS(this.analytics),
      directNumbers: this.directNumbers.list.map((dn) => dn.serialize()),
      settings: this.settings.serialize(),
    }
  }
}

export default UserModel
