import { action, makeAutoObservable, runInAction } from 'mobx'

import type Service from '@src/service'
import type { MessageMediaModel } from '@src/service/model'

export default class MediaStore {
  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  async upload(media: MessageMediaModel) {
    if (!media.file || media.isInvalidFileType()) return

    const url = await this.service.conversation.upload(
      media.file,
      action((progress, total) => {
        media.uploadProgress = progress / total
      }),
    )

    runInAction(() => {
      media.url = url
    })
  }
}
