/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { Backoff } from '@openphone/internal-api-client'
import Debug from 'debug'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { Subject } from 'rxjs'

import config from '@src/config'
import EventEmitter from '@src/lib/EventEmitter'
import { markErrorAsIgnored } from '@src/lib/IgnoredError'
import log, { logError } from '@src/lib/log'
import type { WebSocketMessage } from '@src/service/transport/websocket'

const CONNECT_TIMEOUT = 5000
const HEARTBEAT_TIMEOUT = 10000
const RESET_BACKOFF_TIMEOUT = 30000
const WEBSOCKET_TEARDOWN_CODE = 4999

export type WebSocketState = 'open' | 'closed' | 'connecting' | 'reconnecting'

export type WebSocketEvents = {
  open: () => void
  message: (payload: WebSocketMessage) => void
  close: () => void
  error: (error: any) => void
  unauthorized: () => void
  accessDenied: () => void
  authExpired: () => void
}

export default class OPWebSocket extends EventEmitter<WebSocketEvents> {
  accessToken: string | null = null
  state: WebSocketState = 'closed'

  private downtime$ = new Subject<number>()
  private debug = Debug('op:transport:websocket')
  private socket: WebSocket | null = null
  private connectTimeout?: any
  private heartbeatTimeout?: any
  private resetBackoffTimeout?: any
  private lastInboundMessageAt: number | null = null
  private readonly uri: string = `${config.WEBSOCKET_SERVICE_URL}notification/2`
  private readonly backoff = Backoff.exponential({
    factor: 2.0,
    initialDelay: 100,
    maxDelay: 20000,
    randomisationFactor: 0.4,
  })

  get downtime() {
    return this.downtime$.asObservable()
  }

  constructor() {
    super()

    makeObservable<this, 'handleSocketOpen'>(this, {
      state: observable,
      isOpen: computed,
      connect: action,
      disconnect: action,
      handleSocketOpen: action,
    })

    /**
     * When the connection is established, measure the downtime
     */
    reaction(
      () => this.state,
      action((state) => {
        if (state === 'open' && this.lastInboundMessageAt) {
          const downtime = Date.now() - this.lastInboundMessageAt
          this.downtime$.next(downtime)
          this.debug(`Websocket downtime ${downtime}ms`)
        }
      }),
    )

    this.backoff.on('backoff', (_: any, delay: number) => {
      if (this.state === 'closed') {
        this.debug(`Backoff attempt while Websocket is closed`)
        return
      }
      this.debug(`Will attempt to reconnect WebSocket in ${delay}ms`)
    })

    this.backoff.on('ready', (attempt: number) => {
      if (this.state === 'closed') {
        this.debug(`Backoff tick while Websocket is closed`)
        return
      }
      this.debug(`Attempting to reconnect (retry #${attempt + 1})...`)
      this.connect()
    })
  }

  get isOpen() {
    return this.state === 'open'
  }

  connect() {
    if (!this.accessToken) {
      this.emit('unauthorized')
      this.debug('Connect called without an access token')
      return
    }

    this.tearDownConnection()
    const newState = this.state === 'reconnecting' ? 'reconnecting' : 'connecting'
    this.debug(`Connecting (state ${this.state} -> ${newState})...`)
    this.state = newState
    this.socket = new WebSocket(this.uri, [`access_token${this.accessToken}`])
    this.socket.addEventListener('close', this.handleSocketClose)
    this.socket.addEventListener('error', this.handleSocketError)
    this.socket.addEventListener('message', this.handleSocketMessage)
    this.socket.addEventListener('open', this.handleSocketOpen)

    this.connectTimeout = setTimeout(() => {
      this.debug('Connection attempt timed out.')
      this.reconnect()
    }, CONNECT_TIMEOUT)
  }

  reconnect() {
    this.tearDownConnection()
    this.debug('Reconnect...')
    this.state = 'reconnecting'
    try {
      this.backoff.backoff()
    } catch (error) {
      markErrorAsIgnored(
        error,
        "Ignore 'Backoff in progress' errors because there are a number of places where `reconnect()` may be called close together",
      )
      logError(error)
    }
  }

  /**
   * Close the WebSocket connection
   */
  disconnect() {
    this.tearDownConnection()
    this.debug('Disconnect...')
    this.backoff.reset()
    this.state = 'closed'
    this.emit('close')
  }

  /**
   * Send a message through the WebSocket connection.
   * @param message - A message to send to the endpoint.
   * @returns Whether the message was sent.
   */
  send(message: any): boolean {
    // We can't send the message if the WebSocket isn't open
    if (!this.isOpen) return false

    try {
      if (typeof message !== 'string') {
        message = JSON.stringify(message)
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      this.socket?.send(message)
      return true
    } catch (e: unknown) {
      // Some unknown error occurred. Reset the socket to get a fresh session.
      if (e instanceof Error) {
        this.debug(`Error while sending message ${e.message}`)
      }
      this.reconnect()
      return false
    }
  }

  /**
   * Handle events from the WebSocket
   */
  private handleSocketOpen = () => {
    this.debug(`Connection established successfully.`)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    clearTimeout(this.connectTimeout)
    this.state = 'open'
    this.emit('open')
    this.resetBackoffTimeout = setTimeout(() => {
      this.debug(`Resetting backoff timer.`)
      this.backoff.reset()
    }, RESET_BACKOFF_TIMEOUT)
  }

  private handleSocketClose = (event: CloseEvent) => {
    this.debug(`Received close event ${event.code}, wasClean = ${event.wasClean}`)
    if (event.code === 4010) {
      this.emit('unauthorized')
      this.disconnect()
    } else if (event.code === 4030) {
      this.emit('accessDenied')
      this.disconnect()
    } else if (event.code !== WEBSOCKET_TEARDOWN_CODE) {
      this.reconnect()
    }
  }

  private handleSocketError = (error: Event) => {
    log.error(`Received error: (${error})`, { error })
    this.emit('error', error)
  }

  private handleSocketMessage = (message: MessageEvent) => {
    this.setHeartbeatTimeout()
    this.lastInboundMessageAt = Date.now()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    let { data } = message

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      data = JSON.parse(message.data)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      log.warn('Message was not in JSON format', { data })
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    if (data._class === 'Ping') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.send({ _class: 'Pong', id: data.id })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    } else if (data._class === 'AuthUser') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      if (data.tokenStatus === 'refresh' || data.tokenStatus === 'expired') {
        this.emit('unauthorized')
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      } else if (data.tokenStatus === 'invalid') {
        this.emit('unauthorized')
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      this.emit('message', data)
    }
  }

  private setHeartbeatTimeout() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    clearTimeout(this.heartbeatTimeout)
    this.heartbeatTimeout = setTimeout(() => {
      this.debug(`No messages received in ${HEARTBEAT_TIMEOUT / 1000}s. Reconnecting...`)
      this.reconnect()
    }, HEARTBEAT_TIMEOUT)
  }

  private tearDownConnection() {
    this.debug(`tearDownConnection... (state = ${this.state})`)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    clearTimeout(this.connectTimeout)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    clearTimeout(this.heartbeatTimeout)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    clearTimeout(this.resetBackoffTimeout)

    if (!this.socket) {
      this.debug(`tearDownConnection: Socket doesn't exist`)
      return
    }

    this.socket.removeEventListener('close', this.handleSocketClose)
    this.socket.removeEventListener('error', this.handleSocketError)
    this.socket.removeEventListener('message', this.handleSocketMessage)
    this.socket.removeEventListener('open', this.handleSocketOpen)

    if (
      this.socket.readyState === WebSocket.CONNECTING ||
      this.socket.readyState === WebSocket.OPEN
    ) {
      this.debug(`tearDownConnection: closing socket`)
      this.socket.close(WEBSOCKET_TEARDOWN_CODE)
    }

    this.socket = null
  }
}
