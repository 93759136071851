import defaultSounds from './defaultSounds'

export interface SoundURLs {
  callEnded: string
  callStarted: string
  error: string
  missedCall: string
  newMessage: string
  primaryRingtone: string
  reaction: string
  secondaryNotification: string
  secondaryRingtone: string
  textReceived: string
}

export default class SoundUiStore {
  private cache = new Map<string, HTMLAudioElement>()

  constructor(protected urls: SoundURLs = defaultSounds) {}

  url(name: keyof SoundURLs) {
    return this.urls[name]
  }

  async play(name: keyof SoundURLs) {
    if (!this.cache.has(name)) {
      this.cache.set(name, new Audio(this.url(name)))
    }
    await this.cache
      .get(name)
      ?.play()
      .catch((error) => {
        if (error instanceof DOMException && error.name === 'NotAllowedError') return
        throw error
      })
  }
}
