import cx from 'classnames'
import { forwardRef } from 'react'

import type { TypographyVariant } from '@src/theme'
import { theme } from '@ui/theme'

import * as styles from './Typography.css'

export type TypographyColor =
  | 'inherit'
  | 'primary'
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary'
  | 'error'
  | 'placeholder'
  | `rgb(${string})`

export type TypographyWeight = 'regular' | 'medium' | 'semibold' | 'bold'

export type TypographyTransform =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'initial'
  | 'inherit'
export interface TypographyProps<C extends React.ElementType> extends React.HTMLProps<C> {
  /**
   * The component that will wrap the text. If none is provided it will default to `<div>`.
   */
  component?: C

  /**
   * The design system variant of the text.
   */
  variant?: TypographyVariant | 'inherit'

  /**
   * The design system color of the text.
   */
  color?: TypographyColor

  /**
   * If true, the text will display an ellipsis instead of wrapping.
   *
   * @default false
   */
  nowrap?: boolean

  /**
   * The design system font weight of the text.
   *
   * - `regular`: 450
   * - `medium`: 550
   * - `semibold`: 650
   * - `bold`: 750
   */
  fontWeight?: TypographyWeight

  /**
   * @default none
   */
  textTransform?: TypographyTransform
}

const Typography = forwardRef(
  <T extends React.ElementType>(
    {
      component,
      variant,
      color,
      fontWeight,
      textTransform,
      className,
      nowrap,
      style,
      ...props
    }: TypographyProps<T>,
    outerRef,
  ) => {
    const Component: React.ElementType = component ?? 'div'

    return (
      <Component
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        ref={outerRef}
        className={cx(
          className,
          styles.typography({
            variant,
            fontWeight,
          }),
          {
            [styles.noWrap]: nowrap,
          },
        )}
        style={{
          textTransform,
          color: getColor(color),
          ...style,
        }}
        {...props}
      />
    )
  },
)

export default Typography

const getColor = (color?: TypographyColor): string | undefined => {
  switch (color) {
    case 'primary':
      return `rgb(${theme.palette.primary.strong})`
    case 'textPrimary':
      return `rgb(${theme.palette.gray[1]})`
    case 'textSecondary':
      return `rgb(${theme.palette.gray[2]})`
    case 'textTertiary':
      return `rgb(${theme.palette.gray[3]})`
    case 'error':
      return `rgb(${theme.palette.secondary.red.default})`
    case 'placeholder':
      return `rgb(${theme.palette.gray[1]}, 0.4)`
    default:
      return color
  }
}
