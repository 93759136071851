import { useEffect } from 'react'

import { useAppStore } from '@src/app/context'

const useFavicon = () => {
  const { unreadActivitiesCount, config } = useAppStore()

  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement | null
    if (!favicon) return

    const environment = config.ENV

    if (unreadActivitiesCount > 0) {
      favicon.href = `/assets/favicon-unread-${environment}.png`
    } else {
      favicon.href = `/assets/favicon-${environment}.png`
    }
  }, [config.ENV, unreadActivitiesCount])
}

export default useFavicon
