import { mergeRefs } from '@react-aria/utils'
import { observer } from 'mobx-react-lite'
import { forwardRef, useEffect, useRef } from 'react'

import type { DecodableMessageMedia } from '@src/service/model'

export interface ImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  media: DecodableMessageMedia
  thumbnail?: boolean
}

const ImageView: React.ForwardRefRenderFunction<HTMLImageElement, ImageProps> = function (
  { media, thumbnail, ...props },
  ref,
) {
  const internalRef = useRef<HTMLImageElement>(null)
  const mediaRef = mergeRefs<HTMLImageElement>(ref, internalRef)

  useEffect(() => {
    if (!internalRef.current) return

    if (thumbnail && media.thumbnailUrl) {
      internalRef.current.src = media.thumbnailUrl
    } else if (media.url) {
      internalRef.current.src = media.url
    } else if (media.file) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          if (!internalRef.current || !reader.result) return

          if (reader.result instanceof ArrayBuffer) {
            const base64Url = URL.createObjectURL(new Blob([reader.result]))
            internalRef.current.onload = function () {
              URL.revokeObjectURL(base64Url)
            }

            internalRef.current.src = base64Url
          } else {
            internalRef.current.src = reader.result
          }
        },
        false,
      )
      reader.readAsDataURL(media.file)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [media])

  return <img {...props} ref={mediaRef} />
}

export default observer(forwardRef(ImageView))
