import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import type { AriaAttributes, ReactNode, FunctionComponent } from 'react'
import { useRef, useEffect, useState } from 'react'

import useTransition from '@src/lib/hooks/useTransition'
import VisuallyHidden from '@ui/VisuallyHidden'

import * as styles from './RotatingText.css'

interface RotatingTextProps {
  texts: (string | ReactNode)[]
  height?: number
  wait?: number
  animationDuration?: number
  align?: 'start' | 'center' | 'end'
  initiallyHidden?: boolean
  renderFunction: FunctionComponent<{ children: ReactNode; className?: string }>
}

export default function RotatingText({
  texts,
  height = 22,
  wait = 1000,
  animationDuration = 700,
  align = 'center',
  initiallyHidden = false,
  'aria-hidden': ariaHidden,
  renderFunction,
}: RotatingTextProps & Pick<AriaAttributes, 'aria-hidden'>) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const state = useTransition(true, { enterDelay: 3000 })
  const hidden = initiallyHidden ? state !== 'entered' : false
  const [index, setIndex] = useState(0)

  const currentText = texts[index % texts.length]
  const nextText = texts[(index + 1) % texts.length]

  useEffect(() => {
    if (hidden || !wrapperRef.current || texts.length === 1) return

    const animation = wrapperRef.current.animate(
      [{ transform: `none` }, { transform: `translateY(${-height}px)` }],
      {
        duration: animationDuration,
        easing: 'cubic-bezier(0.79,0.14,0.15,0.86)',
        delay: wait,
        fill: 'both',
      },
    )

    const onAnimationFinish = () => {
      setIndex((prev) => prev + 1)
      setTimeout(() => animation.play())
    }

    animation.addEventListener('finish', onAnimationFinish)

    return () => {
      animation.removeEventListener('finish', onAnimationFinish)
    }
  }, [height, animationDuration, wait, hidden, texts.length])

  return (
    <>
      <div
        aria-hidden={ariaHidden}
        className={cx(styles.root, { [styles.hidden]: hidden })}
        style={assignInlineVars({ [styles.heightVar]: `${height}px` })}
      >
        <div ref={wrapperRef} className={styles.wrapper({ align })}>
          {renderFunction({ children: currentText, className: styles.text })}
          {renderFunction({ children: nextText, className: styles.text })}
        </div>
      </div>
      <VisuallyHidden>{currentText}</VisuallyHidden>
    </>
  )
}
