import { makeAutoObservable, toJS } from 'mobx'

import { parseDate, removeAtIndex } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import objectId from '@src/lib/objectId'
import type ContactStore from '@src/service/contact-store'
import type { CodableContactNoteReaction } from '@src/service/model/reactions'
import {
  ContactNoteReactionModel,
  isCodableContactNoteReaction,
} from '@src/service/model/reactions'

import type { Enrichment } from './activity'
import type { Model } from './base'
import type { Contact } from './contact'

export interface EncodableNote {
  createdAt: number | null
  deletedAt: number | null
  id: string
  private: boolean | null
  text: string | null
  updatedAt: number | null
  userId: string | null
  clientId: string | null
  reactions: CodableContactNoteReaction[]
}

export interface DecodableNote extends EncodableNote {
  enrichment: Enrichment | null
}

// add contactId and noteId to reaction if they are not present
// this is a temporary fix for the data inconsistency
const addContactIdAndNoteIdIfNeeded = (
  reaction: CodableContactNoteReaction,
  contactId: string,
  noteId: string,
): CodableContactNoteReaction => {
  if (typeof reaction === 'object' && reaction !== null) {
    if (!reaction.contactId) {
      reaction.contactId = contactId
    }

    if (!reaction.noteId) {
      reaction.noteId = noteId
    }
  }

  return reaction
}

class NoteModel implements EncodableNote, Model {
  createdAt: number | null = Date.now()
  deletedAt: number | null = null
  id: string = objectId()
  private: boolean | null = null
  text: string | null = null
  updatedAt: number | null = Date.now()
  userId: string | null = null
  enrichment: Enrichment | null = null
  reactions: ContactNoteReactionModel[] = []
  clientId: string | null = null

  constructor(
    private contactStore: ContactStore,
    public contact: Contact,
    attrs: Partial<EncodableNote> = {},
  ) {
    this.deserialize(attrs)

    makeAutoObservable(this, {}, { autoBind: true })
  }

  update(text: string) {
    this.text = text
    return this.contactStore.putNote(this)
  }

  delete() {
    this.contact.notes = this.contact.notes.filter((n) => n !== this)
    return this.contactStore.deleteNote(this)
  }

  deleteReaction(reaction: ContactNoteReactionModel) {
    const reactionIndex = this.reactions.findIndex((r) => r === reaction)
    this.reactions = removeAtIndex(this.reactions, reactionIndex)
    return this.contactStore.deleteNoteReaction(this.contact.id, this.id, reaction)
  }

  addReaction(reaction: ContactNoteReactionModel) {
    this.reactions.push(reaction)
    return this.contactStore.addNoteReaction(this.contact.id, this.id, reaction.toJSON())
  }

  deserialize(json: Partial<EncodableNote>) {
    const { reactions, updatedAt, createdAt, deletedAt, clientId, id, text, userId } =
      json
    const attrs = { clientId, id, private: json['private'], text, userId }

    if (attrs) {
      Object.assign(this, attrs)
    }

    if (updatedAt) {
      this.updatedAt = parseDate(updatedAt)
    }

    if (createdAt) {
      this.createdAt = parseDate(createdAt)
    }

    if (deletedAt) {
      this.deletedAt = parseDate(deletedAt)
    }

    if (reactions) {
      this.reactions = reactions
        .map((reaction) => {
          const normalizedReaction = addContactIdAndNoteIdIfNeeded(
            reaction,
            this.contact.id,
            this.id,
          )

          if (!isCodableContactNoteReaction(normalizedReaction)) {
            return null
          }

          const existing = this.reactions.find((r) => r.id === normalizedReaction.id)

          if (existing) {
            return existing.deserialize(normalizedReaction)
          } else {
            return new ContactNoteReactionModel(normalizedReaction)
          }
        })
        .filter(isNonNull)
    }
    return this
  }

  serialize(): DecodableNote {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      private: this.private,
      text: this.text,
      enrichment: toJS(this.enrichment),
      updatedAt: this.updatedAt,
      userId: this.userId,
      reactions: this.reactions.map((r) => r.serialize()),
      clientId: this.clientId,
    }
  }

  toJSON(): EncodableNote {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      private: this.private,
      text: this.text,
      updatedAt: this.updatedAt,
      userId: this.userId,
      clientId: this.clientId,
      reactions: this.reactions.map((r) => r.toJSON()),
    }
  }
}

export default NoteModel
