import type AppStore from '@src/app/AppStore'

import MMSProtocolHandler, { PROTOCOL as PROTOCOL_MMS } from './MMSProtocolHandler'
import OpenPhoneProtocolHandler, {
  PROTOCOL as PROTOCOL_OPENPHONE,
} from './OpenPhoneProtocolHandler'
import type ProtocolHandler from './ProtocolHandler'
import SMSProtocolHandler, { PROTOCOL as PROTOCOL_SMS } from './SMSProtocolHandler'
import TelProtocolHandler, { PROTOCOL as PROTOCOL_TEL } from './TelProtocolHandler'
import WebOpenPhoneProtocolHandler, {
  PROTOCOL as PROTOCOL_WEB_OPENPHONE,
} from './WebOpenPhoneProtocolHandler'

const PROTOCOLS = [
  PROTOCOL_MMS,
  PROTOCOL_SMS,
  PROTOCOL_TEL,
  PROTOCOL_OPENPHONE,
  PROTOCOL_WEB_OPENPHONE,
] as const

export type Protocol = (typeof PROTOCOLS)[number]

const isProtocol = (protocol: string): protocol is Protocol =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
  PROTOCOLS.includes(protocol as any)

/**
 * Routes incoming URLs to their respective handlers.
 */
export default class URLRouter {
  protected readonly handlers: { [P in Protocol]: ProtocolHandler }

  readonly protocols: ReadonlySet<Protocol> = new Set(PROTOCOLS)
  readonly webProtocols: ReadonlySet<Protocol>
  readonly desktopProtocols: ReadonlySet<Protocol>

  constructor(protected app: AppStore) {
    this.handlers = {
      [PROTOCOL_MMS]: new MMSProtocolHandler(this.app),
      [PROTOCOL_SMS]: new SMSProtocolHandler(this.app),
      [PROTOCOL_TEL]: new TelProtocolHandler(this.app),
      [PROTOCOL_OPENPHONE]: new OpenPhoneProtocolHandler(this.app),
      [PROTOCOL_WEB_OPENPHONE]: new WebOpenPhoneProtocolHandler(this.app),
    }

    this.webProtocols = new Set(
      PROTOCOLS.filter((protocol) => this.handlers[protocol].web),
    )

    this.desktopProtocols = new Set(
      PROTOCOLS.filter((protocol) => this.handlers[protocol].desktop),
    )
  }

  route(incomingUrl: URL | string): void {
    const url = typeof incomingUrl === 'string' ? new URL(incomingUrl) : incomingUrl

    if (!url) return

    const protocol = this.getRegisteredProtocol(url)

    if (protocol) {
      const handler = this.handlers[protocol]
      handler.handle(url)
      this.app.focus()
    }
  }

  protected getRegisteredProtocol(url: URL): Protocol | null {
    // trim off the colon in the url protocol
    const protocol = url.protocol.substring(0, url.protocol.length - 1)

    if (!isProtocol(protocol)) {
      return null
    }

    return protocol
  }
}
