import { useAppStore } from '@src/app/context'

import GenericErrorView from './GenericErrorView'
import * as styles from './GlobalErrorView.css'

interface GlobalErrorViewProps {
  error: unknown
  reset: () => void
}

const GlobalErrorView = function ({ error, reset }: GlobalErrorViewProps) {
  const app = useAppStore()

  function reload() {
    if (app.update.updateReady) {
      app.update.installAndRestart()
    } else {
      reset()
    }
  }

  return (
    <div className={styles.root}>
      <GenericErrorView
        error={error}
        reset={reload}
        title="Oops..."
        description="Seems like something broke. We got a report of the problem. Reloading the app should restore your previous session."
      />
    </div>
  )
}

export default GlobalErrorView
