/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable } from 'mobx'

import Collection from '@src/service/collections/Collection'

import type Service from '.'
import { ParticipantModel } from './model'

export default class ParticipantStore {
  readonly collection = new Collection<ParticipantModel>({
    idKey: 'phoneNumber',
    bindElements: true,
  })

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get(phoneNumber: string) {
    return this.collection.get(phoneNumber)
  }

  getOrCreate(phoneNumber: string) {
    if (!this.collection.has(phoneNumber)) {
      this.collection.put(new ParticipantModel(this.root, phoneNumber))
    }
    return this.get(phoneNumber)
  }
}
