import { makeAutoObservable } from 'mobx'

import assertNever from '@src/lib/assertNever'
import type { FileType } from '@src/lib/file'
import { fileSize, fileType, fileTypeFromExtension, isValidFileType } from '@src/lib/file'
import uuid from '@src/lib/uuid'
import type {
  CodableCallTranscript,
  CallTranscriptModel,
} from '@src/service/model/CallTranscriptModel'

import type { Model } from './base'
import { thumbnailUrl } from './utils'

export interface DecodableMessageMedia {
  id: string
  name?: string | null
  type?: string | null
  file?: File | null
  url?: string | null
  thumbnailUrl?: string
  transcript?: CallTranscriptModel | null
}

export interface CodableMessageMedia {
  id: string
  type: string | null
  url: string | null
  name: string | null
  length: number | null
  transcript?: CodableCallTranscript | null
}

class MessageMediaModel implements DecodableMessageMedia, Model {
  id: string = uuid()
  type: string | null = null
  url: string | null = null
  file: File | null = null
  name: string | null = null
  uploadProgress = 0
  length = 0

  constructor(attrs?: Partial<CodableMessageMedia> | Partial<DecodableMessageMedia>) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get thumbnailUrl(): string {
    return thumbnailUrl(this.url ?? '', { width: 500, quality: 100 })
  }

  get size() {
    if (this.length) {
      return fileSize(this.length)
    } else if (this.file) {
      return fileSize(this.file.size)
    } else {
      return null
    }
  }

  get nameWithoutExtension() {
    if (!this.name) return null
    const index = this.name.lastIndexOf('.')
    if (index === -1) return this.name

    return this.name.slice(0, index)
  }

  get displayName() {
    // If the name has an extension, we assume the format is similar to `audio.mp3`. If not, it means
    // we are getting a file from the backend with a name similar to `602d2381c23c475e8dc6e2606f4cac01`
    if (this.extension) {
      return this.name
    } else {
      switch (this.fileType) {
        case 'audio':
          return 'Audio file'
        case 'blank':
          return 'File'
        case 'contact':
          return 'Contact file'
        case 'csv':
          return 'CSV file'
        case 'doc':
          return 'Doc file'
        case 'image':
          return 'Image file'
        case 'pdf':
          return 'PDF file'
        case 'presentation':
          return 'Presentation file'
        case 'spreadsheet':
          return 'Spreadsheet file'
        case 'text':
          return 'Text file'
        case 'video':
          return 'Video file'
        case 'zip':
          return 'Zip file'
        default:
          assertNever(
            this.fileType,
            `Unhandled media model fileType displayName: ${this.fileType}`,
          )
      }
    }
  }

  get extension() {
    if (this.name) {
      const index = this.name.lastIndexOf('.')
      if (index === -1) return null

      return this.name.slice(index)
    }

    if (this.url) {
      const url = new URL(this.url)

      const index = url.pathname.lastIndexOf('.')
      if (index === -1) return null

      return url.pathname.slice(index)
    }

    return null
  }

  get typeFromExtension(): FileType {
    return fileTypeFromExtension(this.extension?.slice(1) ?? '')
  }

  get fileType(): FileType {
    let type: FileType = 'blank'

    if (this.type) {
      type = fileType(this.type, this.name)
    } else if (this.file) {
      type = fileType(this.file.type, this.file.path)
    } else {
      type = this.typeFromExtension
    }

    return type
  }

  async makeFileFromUrl() {
    try {
      if (!this.url) return
      const response = await fetch(this.url)
      const content = await response.blob()
      const file = new File([content], this.url)
      this.file = file
      this.length = content.size
    } catch {
      // ignore errors
    }
  }

  deserialize(attrs?: Partial<CodableMessageMedia> | Partial<DecodableMessageMedia>) {
    if (attrs) {
      Object.assign(this, attrs)
    }
    return this
  }

  serialize(): CodableMessageMedia {
    return {
      id: this.id,
      type: this.type,
      url: this.url,
      name: this.name,
      length: this.file?.size ?? this.length,
    }
  }

  isInvalidFileType() {
    return !isValidFileType(this.type ?? '')
  }
}

export function isCodableMessageMedia(item: unknown): item is CodableMessageMedia {
  if (typeof item !== 'object' || item == null) return false

  const requiredProperties: (keyof CodableMessageMedia)[] = ['id', 'name', 'type', 'url']

  return requiredProperties.every((property) => property in item)
}

export default MessageMediaModel
