import { action, computed, makeObservable, observable, toJS } from 'mobx'

import { strictOmit } from '@src/lib'

import type { Model } from './base'

interface MessageConsentBase {
  enabled: true
}

interface MessageConsentDisabled {
  enabled: false
}

interface MobileAppMessageConsent extends MessageConsentBase {
  storeUrl: string
  screenshotUrl: string
  screenshotFilename: string
}

interface LinkMessageConsent extends MessageConsentBase {
  url: string
}

interface TextMessageConsent extends MessageConsentBase {
  text: string
}

interface ImageMessageConsent extends MessageConsentBase {
  url: string
  filename: string
}

type BooleanMessageConsent = MessageConsentBase

interface VerbalMessageConsent extends MessageConsentBase {
  /**
   * Verbal consent description
   */
  text: string

  /**
   * Example of how the customer asks for consent
   */
  askForConsentExample: string
}

export interface RawTrustRegistrationV2FormFields {
  type: 'lv-standard' | 'sole-prop' | 'standard'
  draft: boolean

  // Fields for both lv-standard and sole-prop
  firstName: string | null
  lastName: string | null
  email: string | null
  phoneNumber: string | null
  businessName: string | null
  industry: string | null

  country: string | null
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  postalCode: string | null
  region: string | null

  msgDescription: string | null
  msgConsent: {
    textMessage: BooleanMessageConsent | MessageConsentDisabled
    websiteForm: LinkMessageConsent | MessageConsentDisabled
    mobileApp: MobileAppMessageConsent | MessageConsentDisabled
    physicalForm: ImageMessageConsent | MessageConsentDisabled
    digitalForm: LinkMessageConsent | MessageConsentDisabled
    verbal: VerbalMessageConsent | MessageConsentDisabled
    written: ImageMessageConsent | MessageConsentDisabled
    onSite: BooleanMessageConsent | MessageConsentDisabled
    other: TextMessageConsent | MessageConsentDisabled
  }
  msgSamples: string[]

  // Only for lv-standard
  businessType: string | null
  companyType: string | null
  exchange: string | null
  ticker: string | null
  idType: string | null
  idNumber: string | null
  websiteUrl: string | null
  regionsOfOperation: string[]
  jobTitle: string | null
  jobPosition: string | null

  // Only for sole-prop
  assignedPnId: string | null
  personalUse: boolean

  forbiddenMessageCategoriesConfirmation: boolean
}

type RegistrationStatus = 'pending' | 'approved' | 'rejected' | 'failed' | 'none' | null

interface RegistrationStatusMessage {
  fieldName?: keyof RawTrustRegistrationV2FormFields
  errorCode: number
  errorReason: string
}

export interface RawCompleteTrustRegistrationV2 extends RawTrustRegistrationV2FormFields {
  id: string
  object: 'registration'
  lite: boolean
  etag: string
  createdAt: string
  updatedAt: string
  updating: boolean
  apiVersion: string
  firstSubmittedAt: string | null

  // Statuses
  status: RegistrationStatus
  statusMessages: RegistrationStatusMessage[] | Record<string, unknown>
  brandStatus: RegistrationStatus
  campaignStatus: RegistrationStatus
  stirshakenStatus: RegistrationStatus
  brandSmsStatus: 'pending' | 'required' | 'verified' | 'expired' | null
  activePnId: string | null
}

export type RawLiteTrustRegistrationV2 = Pick<
  RawCompleteTrustRegistrationV2,
  'id' | 'assignedPnId' | 'status' | 'type' | 'lite'
>

export type RawTrustRegistrationV2 =
  | RawCompleteTrustRegistrationV2
  | RawLiteTrustRegistrationV2

function isComplete(raw: RawTrustRegistrationV2): raw is RawCompleteTrustRegistrationV2 {
  return raw.lite !== true
}

export default class TrustRegistrationV2Model implements Model {
  private raw: RawTrustRegistrationV2

  private get completeRaw(): RawCompleteTrustRegistrationV2 {
    if (!isComplete(this.raw)) {
      throw new Error('Registration data is not complete')
    }

    return this.raw
  }

  get id(): string {
    return this.raw.id
  }

  get etag(): string {
    return this.completeRaw.etag
  }

  get type() {
    return this.raw.type
  }

  get status() {
    return this.raw.status ?? 'none'
  }

  get isReadOnly(): boolean {
    return !this.isDraft
  }

  get statusMessages(): RegistrationStatusMessage[] {
    const statusMessages = Array.isArray(this.completeRaw.statusMessages)
      ? this.completeRaw.statusMessages
      : []

    return statusMessages.filter((message) => {
      // 10504 is used when the campaign registration gets rejected and the backend doesn't
      // include context about what caused the rejection. The webapp doesn't need to handle this
      // error code because we already have a generic error message for this case.
      if (message.errorCode === 10504) {
        return false
      }

      return true
    })
  }

  get formFields(): RawTrustRegistrationV2FormFields {
    return strictOmit(this.completeRaw, [
      'id',
      'object',
      'etag',
      'status',
      'statusMessages',
      'brandStatus',
      'campaignStatus',
      'stirshakenStatus',
      'brandSmsStatus',
      'activePnId',
      'apiVersion',
      'createdAt',
      'updatedAt',
      'updating',
      'firstSubmittedAt',
    ])
  }

  get isDraft(): boolean {
    return this.completeRaw.draft === true
  }

  get brandSmsStatus() {
    return this.completeRaw.brandSmsStatus
  }

  get brandStatus() {
    return this.completeRaw.brandStatus ?? 'none'
  }

  get campaignStatus() {
    return this.completeRaw.campaignStatus ?? 'none'
  }

  get stirshakenStatus() {
    return this.completeRaw.stirshakenStatus ?? 'none'
  }

  get activePnId() {
    return this.completeRaw.activePnId
  }

  get assignedPnId() {
    return this.raw.assignedPnId
  }

  get isSubmitted(): boolean {
    return this.completeRaw.firstSubmittedAt !== null
  }

  get isPersonalUse(): boolean {
    return this.type === 'sole-prop' && this.completeRaw.personalUse === true
  }

  get isRegistered(): boolean {
    return this.status === 'approved'
  }

  /**
   * Returns a lite version of the registration that can be read by non-admin
   * users of the workspace.
   */
  get lite() {
    return {
      id: this.id,
      assignedPnId: this.assignedPnId,
      isRegistered: this.isRegistered,
      type: this.type,
    }
  }

  constructor(raw: RawTrustRegistrationV2) {
    this.raw = raw

    makeObservable<this, 'raw' | 'completeRaw'>(this, {
      raw: observable.deep,
      completeRaw: computed,
      id: computed,
      etag: computed,
      type: computed,
      status: computed,
      isReadOnly: computed,
      statusMessages: computed,
      formFields: computed,
      isDraft: computed,
      brandSmsStatus: computed,
      brandStatus: computed,
      campaignStatus: computed,
      stirshakenStatus: computed,
      activePnId: computed,
      assignedPnId: computed,
      isSubmitted: computed,
      isPersonalUse: computed,
      isRegistered: computed,
      lite: computed,
      deserialize: action,
      localUpdate: action,
    })
  }

  deserialize(json: RawTrustRegistrationV2): this {
    this.raw = json
    return this
  }

  serialize(): RawTrustRegistrationV2 {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawTrustRegistrationV2>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
