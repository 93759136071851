import type Heap from './Heap'

export default class VoiceAnalyticsStore {
  constructor(private readonly heap: Heap) {}

  /**
   * Tracks when a call has been initiated by the user
   *
   * @param count number of participants including self
   * @param targets array that represents the participants added to the call, excluding self
   */
  callStarted(
    count: number,
    targets: ('number' | 'contact' | 'inbox' | 'inbox-member' | 'member')[],
  ) {
    this.heap.track('call_started', {
      count,
      targets: targets.join(','),
    })
  }

  /**
   * Tracks when participants are added to the call by the user
   *
   * @param addedCount number of participants that were added
   * @param oldCount number of active participants in the call before the new participants were added
   * @param newCount number of active participants in the call after the new participants were added
   * @param mode represents how the participants were added
   * @param messageLength length of the add/transfer message invite (0 if no message was added to the invite)
   * @param targets array that represents the participants that were added to the call
   */
  callParticipantsAdded(
    addedCount: number,
    oldCount: number,
    newCount: number,
    mode: ('add' | 'add-listener' | 'warm-transfer')[],
    messageLength: number,
    targets: ('number' | 'contact' | 'inbox' | 'inbox-member' | 'member')[],
  ) {
    this.heap.track('call_participants_added', {
      addedCount,
      oldCount,
      newCount,
      mode: mode.join(','),
      messageLength,
      targets: targets.join(','),
    })
  }

  /**
   * Tracks when a participant is removed from the call by the user
   *
   * @param oldCount number of active participants in the call before the participant was removed
   * @param newCount number of active participants in the call after the participant was removed
   * @param target string that represents the type of the participant that was removed from the call
   */
  callParticipantRemoved(
    oldCount: number,
    newCount: number,
    target: 'number' | 'contact' | 'inbox' | 'member',
  ) {
    this.heap.track('call_participant_removed', {
      oldCount,
      newCount,
      target,
    })
  }

  /**
   * Tracks when the user answers a call
   *
   * @param count number of participants on the call, including self after joining
   */
  callAnswered(count: number) {
    this.heap.track('call_answered', { count })
  }

  /**
   * Tracks when the user toggles hold for the whole call
   *
   * @param value whether or not hold was toggled on for the call
   */
  callHoldToggled(value: boolean) {
    this.heap.track('call_hold_toggled', { value })
  }

  /**
   * Tracks when the user toggles hold for a specific participant
   *
   * @param value whether or not hold was toggled on for the participant
   * @param target string that represents the type of the participant for whom hold was toggled
   */
  callParticipantHoldToggled(value: boolean, target: 'number' | 'contact') {
    this.heap.track('call_participant_hold_toggled', { value, target })
  }

  /**
   * Tracks when the call ends for the current user
   *
   * @param duration total duration of the call for the current user (in seconds)
   * @param count total number of participants (including participants that left the call)
   * @param cause the reason the call has ended for the current user
   */
  callEnded(
    duration: number,
    count: number,
    cause: 'leave' | 'end-for-everyone' | 'error' | 'completed',
  ) {
    this.heap.track('call_ended', {
      duration,
      count,
      cause,
    })
  }

  /**
   * Tracks when a user starts manually recording a call
   *
   * @param phoneNumberId
   */
  manualCallRecordingStarted(phoneNumberId: string) {
    this.heap.track('manual_call_recording_started', { phoneNumberId })
  }
}
