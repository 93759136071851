import cx from 'classnames'
import { createContext, useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import { BannersProvider } from '@src/app/banner'
import Banners from '@src/app/banner/Banners'
import OutageBanner from '@src/app/banner/OutageBanner'
import { useAppStore } from '@src/app/context'
import { useStorePartnerStackKey } from '@src/app/onboarding/partnerStack'
import { RecaptchaProvider } from '@src/app/recaptcha'
import { Redirect } from '@src/app/router'
import { isMac } from '@src/config'

import EmailCode from './EmailCode'
import ForgotPassword from './ForgotPassword'
import * as styles from './Login.css'
import LoginOptions from './LoginOptions'
import Password from './Password'
import type { LoginUiStore } from './store'

const LoginStoreContext = createContext<LoginUiStore | null>(null)

function Login() {
  const { login: store } = useAppStore()
  const [isInitialized, setIsInitialized] = useState(false)

  useStorePartnerStackKey()

  useEffect(() => {
    store.setInitialStep().then(() => {
      setIsInitialized(true)
    })
  }, [store])

  return (
    <>
      <BannersProvider>
        <Banners>
          <OutageBanner />
        </Banners>
      </BannersProvider>
      <LoginStoreContext.Provider value={store}>
        <RecaptchaProvider>
          <div className={cx(styles.root, { [styles.rootMac]: isMac })}>
            <Routes>
              <Route path="coupon/*" element={<LoginOptions />} />
              <Route path="signup/*">
                <Route path="code" element={<EmailCode />} />
                <Route path="*" element={<LoginOptions />} />
              </Route>
              <Route path="login">
                <Route index element={<LoginOptions />} />
                <Route path="code" element={isInitialized ? <EmailCode /> : null} />
                <Route
                  path="forgot"
                  element={isInitialized ? <ForgotPassword /> : null}
                />
                <Route path="password" element={isInitialized ? <Password /> : null} />
              </Route>
              <Route path="*" element={<Redirect to="login" />} />
            </Routes>
          </div>
        </RecaptchaProvider>
      </LoginStoreContext.Provider>
    </>
  )
}

export default Login
