import type { IObservableArray, ObservableMap } from 'mobx'
import { action, makeObservable, observable, remove } from 'mobx'
import type { Subscription } from 'rxjs'
import { Subject } from 'rxjs'

import { isArrayOfStrings, partition } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'

import type { ImmutableCollectionOptions } from './ImmutableCollection'
import ImmutableCollection from './ImmutableCollection'

export type CollectionChange<T> =
  | { type: 'put'; objects: readonly T[] }
  | { type: 'delete'; objects: readonly T[] }

export interface CollectionOptions<T extends { [key: string]: any }>
  extends ImmutableCollectionOptions<T> {
  /**
   * Bind elements to this collection.
   *
   * If true, setup and teardown logic on the models will run when they are
   * added or removed from the collection.
   *
   * @default false
   */
  readonly bindElements?: boolean
}

/**
 * Observable collection of items
 */
export default class Collection<
  T extends { [key: string]: any },
> extends ImmutableCollection<T> {
  protected override elements: ObservableMap<string, T> = observable.map({})
  protected override elementIds: IObservableArray<string> = observable.array([])
  protected change$ = new Subject<CollectionChange<T>>()

  protected bindElements: boolean

  constructor({
    idKey,
    bindElements = false,
    filter,
    compare,
  }: CollectionOptions<T> = {}) {
    super(undefined, { idKey, filter, compare })
    this.bindElements = bindElements

    makeObservable<this, 'elements' | 'elementIds'>(this, {
      elements: observable,
      elementIds: observable,
      put: action,
      putBulk: action,
      delete: action,
      deleteBulk: action,
      clear: action,
      replace: action,
      refilter: action,
    })
  }

  put(obj: T) {
    if (!obj) return
    this._put(obj)
    this.change$.next({ type: 'put', objects: [obj] })
  }

  putBulk(objs: readonly T[]) {
    if (!objs || objs.length === 0) return
    objs = objs.filter(isNonNull)
    this._putBulk(objs)
    this.change$.next({ type: 'put', objects: objs })
  }

  delete(o: string | T) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const id = typeof o === 'string' ? o : o[this.idKey]
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    this.deleteByIds([id])
  }

  deleteBulk(objects: string[] | readonly T[]) {
    const ids = isArrayOfStrings(objects)
      ? objects
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        objects.filter(isNonNull).map((o) => o[this.idKey])
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    this.deleteByIds(ids)
  }

  clear() {
    /**
     * Mark all properties as null before deleting
     * so that all relationships get unwound
     */
    const toDelete = [...this.elements.values()].filter(isNonNull)
    if (toDelete.length === 0) return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    if (this.bindElements) this.elements.forEach((el) => el?.tearDown?.())
    this.elements.replace({})
    this.elementIds.replace([])
    this.change$.next({ type: 'delete', objects: toDelete })
  }

  replace(items: readonly T[]) {
    this.clear()
    this.putBulk(items)
  }

  /**
   * Re-run the filter on all collection items.
   *
   * The filter runs only when items are added to the collection, so this is a
   * way to re-run the filter on all items on demand.
   */
  refilter() {
    this.deleteBulk(this.filter ? this.list.filter((item) => !this.filter?.(item)) : [])
  }

  bind(collection: Collection<T>): Subscription {
    this.clear()

    const filteredItems = this.filter
      ? collection.list.filter(this.filter)
      : collection.list
    const items = this.compare ? filteredItems.sort(this.compare) : filteredItems

    this.elements.replace(items.map((item) => [item[this.idKey], item]))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    this.elementIds.replace(items.map((item) => item[this.idKey]))
    this.change$.next({ type: 'put', objects: items })

    return collection.observe((change) => {
      this.applyChange(change)
    })
  }

  observe(handler: (changes: CollectionChange<T>) => void) {
    return this.change$.asObservable().subscribe(handler)
  }

  protected deleteByIds(ids: string[]) {
    const objects = ids.map((id) => this.get(id)).filter(isNonNull)
    if (objects.length === 0) return
    for (const object of objects) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      const id = object[this.idKey]
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
      if (this.bindElements) object.tearDown?.()

      remove(this.elements, id)

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const index = this.elementIds.indexOf(id)

      this.elementIds.splice(index, 1)
    }

    this.change$.next({ type: 'delete', objects })
  }

  protected applyChange(change: CollectionChange<T>) {
    if (change.type === 'put') {
      this.applyPutChange(change.objects)
    } else if (change.type === 'delete') {
      this.applyDeleteChange(change.objects)
    }
  }

  protected applyPutChange(objects: readonly T[]) {
    const [toInsert, toDelete] = this.filter
      ? partition(objects, this.filter)
      : [objects, []]

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    this.deleteBulk(toDelete.map((o) => o.id))
    this.putBulk(toInsert)
  }

  protected applyDeleteChange(objects: readonly T[]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    this.deleteBulk(objects.map((o) => o[this.idKey]))
  }
}
