import { action, makeAutoObservable } from 'mobx'

import { parseDate } from '@src/lib'
import { logError } from '@src/lib/log'
import type { FetchWebhookEventsRequest } from '@src/service'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import WorkspaceModel from '@src/service/model/WorkspaceModel'

import type Service from '.'
import type { CodableGroup, Member, CodableDomain } from './model'
import { GroupModel } from './model'
import type { CodableWebhook } from './model/WebhookModel'
import WebhookModel from './model/WebhookModel'
import type { GroupsRepository } from './worker/repository/GroupsRepository'
import type { WebhooksRepository } from './worker/repository/WebhooksRepository'

export default class WorkspaceStore {
  workspaces: WorkspaceModel[] = []
  private _groups: PersistedCollection<GroupModel, GroupsRepository>
  webhooks: PersistedCollection<WebhookModel, WebhooksRepository>

  constructor(private root: Service) {
    this.handleWebsocket()

    this._groups = new PersistedCollection({
      table: this.root.storage.table('groups'),
      classConstructor: (json: CodableGroup) => new GroupModel(this.root.workspace, json),
    })

    this.webhooks = new PersistedCollection({
      table: this.root.storage.table('webhooks'),
      classConstructor: (json: CodableWebhook) =>
        new WebhookModel(this.root.workspace, json),
      compare: (a, b) =>
        (parseDate(b.createdAt ?? 0) ?? 0) - (parseDate(a.createdAt ?? 0) ?? 0),
    })

    makeAutoObservable(this, {})

    this.webhooks.performQuery((repo) => repo.all())
    this._groups.performQuery((repo) => repo.all())
  }

  get groups() {
    return this._groups.list
  }

  get activeGroups() {
    return this._groups.list.filter((group) => !group.deletedAt)
  }

  get deletedGroups() {
    return this._groups.list.filter((group) => group.deletedAt)
  }

  getMember(id: string): Member | null {
    return this.root.member.collection.get(id)
  }

  fetch() {
    return this.root.transport.workspace.fetch().then(
      action((response) => {
        this.workspaces = response.results.map((attrs) => new WorkspaceModel(attrs))
      }),
    )
  }

  addMember(workspaceId: string) {
    return this.root.transport.workspace.addMember(workspaceId)
  }

  addDomain(verificationEmail: string) {
    if (!this.root.organization.current) return this.handleCurrentOrganizationAbscence()

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.add({ verificationEmail })
  }

  validateDomain(email: string) {
    return this.root.transport.account.domain.validate(email)
  }

  deleteDomain(domainId: string) {
    if (!this.root.organization.current) return this.handleCurrentOrganizationAbscence()

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.delete(domainId)
  }

  fetchDomains(): Promise<{ results: CodableDomain[] }> {
    if (!this.root.organization.current) {
      logError(new Error('Current organization is null'))

      return Promise.resolve({ results: [] })
    }

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.fetch()
  }

  resendCode(domainId: string) {
    if (!this.root.organization.current) return this.handleCurrentOrganizationAbscence()

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.resendCode(domainId)
  }

  verifyDomain(email: string, code: string) {
    if (!this.root.organization.current) return this.handleCurrentOrganizationAbscence()

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.verify({ email, code })
  }

  fetchUserGroups() {
    return this.root.transport.account.organization.groups
      .fetch({ includeDeleted: true })
      .then(
        action((response) => {
          this._groups.load(response)
        }),
      )
  }

  updateGroup(group: CodableGroup) {
    return this.root.transport.account.organization.groups.update(group)
  }

  createGroup(group: CodableGroup) {
    return this.root.transport.account.organization.groups
      .create(group)
      .then(() =>
        this.root.analytics.workspace.userGroupCreated(
          group.members?.length ?? 1,
          group.name ?? '',
        ),
      )
  }

  deleteGroup(id: string) {
    return this.root.transport.account.organization.groups.delete(id)
  }

  saveDraftGroup(group: GroupModel) {
    this._groups.put(group)
  }

  filterDeletedGroupIds(ids: string[]) {
    const idsSet = new Set(ids)

    for (const { id } of this.deletedGroups) {
      idsSet.delete(id)
    }

    return Array.from(idsSet)
  }

  addUser(groupId: string, userId: string) {
    return this.root.transport.account.organization.groups
      .addUser(groupId, userId)
      .then(() => this.root.analytics.workspace.userAddedToGroup())
  }

  deleteUser(groupId: string, userId: string) {
    return this.root.transport.account.organization.groups.deleteUser(groupId, userId)
  }

  createWebhook(webhook: CodableWebhook) {
    return this.root.transport.webhooks
      .create(webhook)
      .then((response) =>
        this.webhooks.put(new WebhookModel(this.root.workspace, response)),
      )
  }

  updateWebhook(webhook: CodableWebhook) {
    return this.root.transport.webhooks.update(webhook)
  }

  deleteWebhook(webhook: CodableWebhook) {
    return this.root.transport.webhooks.delete(webhook)
  }

  fetchWebhooks() {
    return this.root.transport.webhooks
      .fetch()
      .then((response) => this.webhooks.load(response))
  }

  sendTestRequest(id: string, data: Record<string, any>) {
    return this.root.transport.webhooks.sendTestRequest(id, data)
  }

  resendEvent(webhookId: string, eventId: string) {
    return this.root.transport.webhooks.resendEvent(webhookId, eventId)
  }

  fetchWebhookEvents(params: FetchWebhookEventsRequest) {
    return this.root.transport.webhooks.fetchWebhookEvents(params)
  }

  exportWorkspaceData(exportTypes: string[]) {
    return this.root.transport.report.export.workspaceData(exportTypes)
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'group-updated':
        case 'group-deleted':
          this._groups.load(data.group)
          return
        case 'webhook-delete':
          this.handleWebhookDelete(data.webhook)
          return
        case 'webhook-update':
          this.handleWebhookUpdate(data.webhook)
          return
      }
    })
  }

  private handleWebhookDelete(webhook: CodableWebhook) {
    this.webhooks.delete(new WebhookModel(this.root.workspace, webhook))
  }

  private handleWebhookUpdate(value: CodableWebhook) {
    if (!value.id) return

    const webhook = this.webhooks.get(value.id)
    if (webhook) {
      webhook.deserialize(value)
    } else {
      this.webhooks.put(new WebhookModel(this.root.workspace, value))
    }
  }

  private handleCurrentOrganizationAbscence() {
    if (this.root.transport.online) {
      logError(new Error('Current organization is null'))
      return Promise.reject(`An error occurred`)
    } else {
      return Promise.reject(`Can't perform this action while offline`)
    }
  }
}
